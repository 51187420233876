import { dayjs, getTimeZoneByAddress, setTimezone } from '@esg/shared';
import { defaultShifts, LocationOption } from '@esg/ui';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface AutoLocaleProviderProps {
    readonly countriesOption: LocationOption[];
    readonly statesOption: LocationOption[];
    readonly children?: React.ReactNode;
}

export default function AutoLocaleProvider(props: AutoLocaleProviderProps) {
    const { countriesOption, statesOption, children } = props;
    const { watch, setValue, reset } = useFormContext();
    const firstRender = useRef(true);
    const initTimezone = JSON.parse(localStorage.getItem('initDataSetup') ?? '{}' ).timezone;

    useEffect(()=>{
        if (initTimezone != null) {
            setTimezone(initTimezone);
        }
    },[initTimezone]);
    
    // set timezone and currency when change country and state
    useEffect(()=> {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        const country = countriesOption.find((o: LocationOption) => o.value === watch('country'));
        const state = statesOption.find((o : LocationOption) => o.value === watch('state'));

        const address = `${state?.label ?? ''}, ${country?.label}`;
        getTimeZoneByAddress(address).then((timezoneRecommend) => {
            if(timezoneRecommend) {
                setValue('timezone', timezoneRecommend.timeZoneId);
                setTimezone(timezoneRecommend.timeZoneId);
            }
        });
        
        if (country) {
            setValue('currency', country.currency);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[watch('country'), watch('state')]);

    // reset business hour for new timezone
    useEffect(() => {
        if(initTimezone != watch('timezone')) {
            const values = watch();
            const newBusinessHour = defaultShifts.map((item) => {
                return {
                    ...item,
                    startTime: dayjs().startOf('day').hour(8).toISOString(),
                    endTime: dayjs().startOf('day').hour(22).toISOString(),
                };
            });

            reset({
                ...values,
                businessHour: newBusinessHour,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('timezone')]);

    return children;
}
