import { HttpRequestData, httpUtil } from '@esg/framework';

import { env } from '../../config';

interface ConnectAuthorizeRequest extends HttpRequestData {
}

export const connectAuthorize = httpUtil.createHttpRequestMeta<
    ConnectAuthorizeRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/connect/authorize',
    method: 'POST',
    authentication: 'bearer'
});
