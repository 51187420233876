import { forgotPassword } from './forgotPassword';
import { register } from './register';
import { resentEmailVerify } from './resentEmailVerify';
import { resetPassword } from './resetPassword';
import { verifyEmail } from './verifyEmail';
export * from './upsertPassword';
export * from './disconnectProvider';

export const accountApi = {
    forgotPassword,
    register,
    verifyEmail,
    resetPassword,
    resentEmailVerify,
};
