const setupPath = '/setup';

export const setupPaths = {
    auth: setupPath,
    info: `${setupPath}/0`,
    branchSetup: `${setupPath}/1`,
    businessCategory: `${setupPath}/2`,
    serviceSetup: `${setupPath}/3`,
    employeeSetup: `${setupPath}/4`,
};
