import { httpUtil } from '@esg/framework';

import { env } from '../../config';

interface ForgotPasswordRequest {
    readonly body: {
        readonly email: string;
    };
}

export const forgotPassword = httpUtil.createHttpRequestMeta<
    ForgotPasswordRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/account/send-reset-password-email',
    method: 'POST',
});
