export const authTheme = {
    layout: {
        backgroundPattern: '/assets/auth/Pattern.svg',
        backgroundImage: '/assets/auth/Data_img.svg',
        logo: '/assets/auth/logo_sologan_black.svg',
        contentWith: 430,
        logoWith: 214,
    },
    social: {
        googleIcon: '/assets/auth/GG_icon.svg',
        facebookIcon: '/assets/auth/FB_icon.svg',
    },
    icon: {
        mailIcon: '/assets/auth/Mail_icon.svg',
        arrowLeftIcon: '/assets/auth/Arrow_Left.svg',
        keyIcon: '/assets/auth/Key_icon.svg',
        unlockIcon: '/assets/auth/Rp_icon.svg',
        checkIcon: '/assets/auth/Check_icon.svg',
    },
};
