import {
    Block,
    Header,
    Paragraph,
    Spacer,
    Text,
    Translate,
} from '@esg/ui';
import { Image } from 'antd';
import React from 'react';

interface AuthHeaderProps {
    headTranslateCode: string;
    descTranslateCode: string;
    srcLogo?: string;
    userMail?: string;
}

export const AuthHeader = (props: AuthHeaderProps) => {
    const { descTranslateCode, headTranslateCode, srcLogo } = props;
    return (
        <Block textAlign='center'>
            {srcLogo && <Image src={srcLogo} preview={false} />}
            <Spacer top='md' bottom={12}>
                <Header level={1}>
                    <Translate translateCode={headTranslateCode} />
                </Header>
            </Spacer>
            <Paragraph fontSize={16}>
                <Translate translateCode={descTranslateCode} />
                &nbsp;
                {props.userMail ? (
                    <Text fontWeight='bold' fontSize={16}>
                        {props.userMail}
                    </Text>
                ) : null}
            </Paragraph>
        </Block>
    );
};
