import { EmployeeInitCreate, ServiceCategoryInitCreate, ServiceInitCreate } from '@esg/business-account';
import { dayjs, JOB_STATUS } from '@esg/shared';

export const jobTypeStatusOption = [
    { 
        label: 'Active',
        value: JOB_STATUS.ACTIVE,
    },
    { 
        label: 'Separated',
        value: JOB_STATUS.SEPARATED,
    }
];

export const sampleEmployeeData: EmployeeInitCreate[] = [
    {
        id: 0,
        firstName: 'Maria',
        lastName: 'Anderson',
        email: 'MariaAnderson@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample.png',
    },
    {
        id: 1,
        firstName: 'Michelle',
        lastName: 'Martinez',
        email: 'MichelleMartinez@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample1.png',
    },
    {
        id: 2,
        firstName: 'Ethan',
        lastName: 'Olson',
        email: 'EthanOlson@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample2.png',
    },
    {
        id: 3,
        firstName: 'Cleve',
        lastName: 'Langosh',
        email: 'Laurel58@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample3.png',
    },
    {
        id: 4,
        firstName: 'Frederik',
        lastName: 'Kuphal',
        email: 'Hal50@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample4.png',
    },
    {
        id: 5,
        firstName: 'Chandler',
        lastName: 'Gaylord',
        email: 'Leonardo78@gmail.com',
        type: 'sample',
        jobStartDate: dayjs(),
        jobStatus: JOB_STATUS.ACTIVE,
        avatar: '/assets/images/employee-sample5.png',
    }
];

export const sampleServiceData: ServiceInitCreate[] = [
    {
        id: 0,
        tempCategoryId: 0,
        name: 'Hair cut',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
    {
        id: 1,
        tempCategoryId: 0,
        name: 'Color Treatment',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
    {
        id: 2,
        tempCategoryId: 0,
        name: 'Hair spa',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
    {
        id: 3,
        tempCategoryId: 1,
        name: 'Manicure',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
    {
        id: 4,
        tempCategoryId: 1,
        name: 'Pedicure',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
    {
        id: 5,
        tempCategoryId: 1,
        name: 'Gel Nail',
        duration: 45,
        price: 15,
        description: '',
        type: 'sample',
    },
];

export const sampleCategoryData: ServiceCategoryInitCreate[] = [
    {
        tempId: 0,
        name: 'Hair salon',
        type: 'sample',
    },
    {
        tempId: 1,
        name: 'Nail salon',
        type: 'sample',
    },
];