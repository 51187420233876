import React from 'react';
import { IconProps } from '../../Types';

export const IconNailsSalon = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M23.658 17.3177C23.4513 17.0766 23.1625 16.9438 22.8449 16.9438H9.6552C9.33758 16.9438 9.04883 17.0766 8.84208 17.3177C8.63545 17.5587 8.54826 17.8642 8.59645 18.1779L10.197 28.5817C10.278 29.1078 10.7233 29.4898 11.2556 29.4898H21.2443C21.7768 29.4898 22.2221 29.1078 22.3029 28.5815L23.9035 18.1778C23.9518 17.8642 23.8646 17.5587 23.658 17.3177ZM17.2728 25.727H15.2273C14.4819 25.727 13.9437 25.0135 14.1485 24.2968L14.7625 22.1479C14.9001 21.6662 15.3403 21.3342 15.8413 21.3342H16.6588C17.1598 21.3342 17.6 21.6662 17.7376 22.1479L18.3516 24.2968C18.5565 25.0135 18.0183 25.727 17.2728 25.727Z'
                fill='#9BD7FD'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.35087 18.3696C7.35087 18.3696 7.35087 18.3696 7.35087 18.3696L8.95144 28.7732C9.1264 29.9105 10.105 30.75 11.2557 30.75H21.2443C22.395 30.75 23.3736 29.9105 23.5485 28.7732L25.1491 18.3696C25.3664 16.9574 24.2737 15.6838 22.8448 15.6838H9.65514C8.22625 15.6838 7.13362 16.9573 7.35087 18.3696ZM6.11541 18.5597C5.78167 16.3902 7.46015 14.4338 9.65514 14.4338H22.8448C25.0398 14.4338 26.7183 16.3903 26.3846 18.5597L24.784 28.9633C24.5152 30.7103 23.0119 32 21.2443 32H11.2557C9.48804 32 7.98475 30.7103 7.71597 28.9633L6.11541 18.5597L6.11541 18.5597Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.1216 11.9184C12.5952 11.9184 12.1685 12.3451 12.1685 12.8715V14.4337H20.3317V12.8715C20.3317 12.3451 19.905 11.9184 19.3786 11.9184H13.1216ZM10.9185 12.8715C10.9185 11.6548 11.9048 10.6684 13.1216 10.6684H19.3786C20.5953 10.6684 21.5817 11.6548 21.5817 12.8715V15.0587C21.5817 15.4039 21.3019 15.6837 20.9567 15.6837H11.5435C11.1983 15.6837 10.9185 15.4039 10.9185 15.0587V12.8715Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.551 1.25C14.7226 1.25 14.051 1.92155 14.051 2.75V10.6684H18.449V2.75C18.449 1.92155 17.7774 1.25 16.949 1.25H15.551ZM12.801 2.75C12.801 1.2312 14.0322 0 15.551 0H16.949C18.4678 0 19.699 1.2312 19.699 2.75V11.2934C19.699 11.6386 19.4191 11.9184 19.074 11.9184H13.426C13.0808 11.9184 12.801 11.6386 12.801 11.2934V2.75Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.3634 22.3196C15.3634 22.3196 15.3634 22.3196 15.3634 22.3196L14.7494 24.4685C14.6587 24.786 14.8971 25.102 15.2273 25.102H17.2728C17.603 25.102 17.8414 24.786 17.7506 24.4686L17.7506 24.4685L17.1367 22.3196C17.1367 22.3196 17.1367 22.3196 17.1367 22.3196C17.0757 22.1063 16.8807 21.9592 16.6588 21.9592H15.8412C15.6193 21.9592 15.4243 22.1063 15.3634 22.3196ZM14.1615 21.9762C14.3758 21.2262 15.0613 20.7092 15.8412 20.7092H16.6588C17.4388 20.7092 18.1243 21.2262 18.3386 21.9762L18.9525 24.125C18.9525 24.125 18.9525 24.1251 18.9525 24.1251C19.2715 25.2411 18.4334 26.352 17.2728 26.352H15.2273C14.0666 26.352 13.2287 25.2411 13.5475 24.1251C13.5475 24.1251 13.5475 24.1251 13.5475 24.1251L14.1615 21.9762Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.25 14.4337C16.5952 14.4337 16.875 14.7135 16.875 15.0587V21.3342C16.875 21.6794 16.5952 21.9592 16.25 21.9592C15.9048 21.9592 15.625 21.6794 15.625 21.3342V15.0587C15.625 14.7135 15.9048 14.4337 16.25 14.4337Z'
                fill='#0C6FF3'
            />
        </svg>
    );
};
