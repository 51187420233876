export const translateCodes = {
    //#region [Common]
    SAVE: 'SAVE',
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    NAME: 'NAME',
    CANCEL: 'CANCEL',
    JOIN_IZISALON: 'JOIN_IZISALON',
    JOIN: 'JOIN',
    //#endregion [Common]

    //#region [Layout]
    PLATFORM_FOR_SALON_SUCCESS: 'PLATFORM_FOR_SALON_SUCCESS',
    INTELLIGENT_EFFICIENT_EFFORTLESS: 'INTELLIGENT_EFFICIENT_EFFORTLESS',
    //#endregion [Layout]

    //#region [Social]
    CONTINUE_WITH_GOOGLE: 'CONTINUE_WITH_GOOGLE',
    CONTINUE_WITH_FACEBOOK: 'CONTINUE_WITH_FACEBOOK',
    //#endregion [Social]

    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    DO_NOT_HAVE_ACCOUNT: 'DO_NOT_HAVE_ACCOUNT',

    //#region [Login Page]
    LOGIN_BUTTON: 'LOGIN',
    LOGIN_LINK: 'LOGIN_LINK',
    LOGIN_HEADER: 'LOGIN_HEADER',
    LOGIN_FORM_REMEMBER_ME: 'LOGIN_FORM_REMEMBER_ME',
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    JOIN_IZISALON_DESC: 'JOIN_IZISALON_DESC',
    //#endregion [Login Page]

    //#region [Sign Up Page]
    SIGN_UP_LINK: 'SIGN_UP_LINK',
    SIGN_UP_BUTTON: 'SIGN_UP_BUTTON',
    SIGN_UP_HEADER: 'SIGN_UP_HEADER',
    GET_STARTED: 'GET_STARTED',
    BY_SIGNING_UP: 'BY_SIGNING_UP',
    HAVE_AN_ACCOUNT_QUESTION: 'HAVE_AN_ACCOUNT_QUESTION',
    GENERAL_TERMS: 'GENERAL_TERMS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    //#endregion [Sign Up Page]

    //#region [Verify Email Page]
    CHECK_YOUR_EMAIL: 'CHECK_YOUR_EMAIL',
    SENT_VERIFY_RESET_PASS: 'SENT_VERIFY_RESET_PASS',
    SENT_VERIFY_CONFIRM_CODE_TO: 'SENT_VERIFY_CONFIRM_CODE_TO',
    DIDNT_RECEIVE_THE_EMAIL: 'DIDNT_RECEIVE_THE_EMAIL',
    CLICK_TO_RESEND: 'CLICK_TO_RESEND',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    //#endregion [Verify Email Page]

    //#region [Check Email Page]
    SEND_RESET_INSTRUCTIONS: 'SEND_RESET_INSTRUCTIONS',
    OPEN_EMAIL_APP: 'OPEN_EMAIL_APP',
    BACK_TO_LOG_IN: 'BACK_TO_LOG_IN',
    //#endregion [Check Email Page]

    //#region [Reset password Page]
    RESET_PASSWORD: 'RESET_PASSWORD',
    SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
    NEW_PW_MUST_DIFF_OLD_PW: 'NEW_PW_MUST_DIFF_OLD_PW',
    PASSWORD_RESET: 'PASSWORD_RESET',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
    MAX_LENGTH_EMAIL: 'MAX_LENGTH_EMAIL',
    PASSWORD_NOT_MATCH:'PASSWORD_NOT_MATCH',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    RESEND_EMAIL_RESET_PASSWORD: 'RESEND_EMAIL_RESET_PASSWORD',
    RESEND_EMAIL_VERIFY_SUCCESS:  'RESEND_EMAIL_VERIFY_SUCCESS',
    CHECK_YOUR_EMAIL_NOW: 'CHECK_YOUR_EMAIL_NOW',
    INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_EMAIL_ENTERED: 'INVALID_EMAIL_ENTERED',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    VALIDATE_PASSWORD: 'VALIDATE_PASSWORD', 
    VALIDATE_CONFIRM_PASSWORD: 'VALIDATE_CONFIRM_PASSWORD', 
    //#endregion [Reset password Page]

    //#region [Fields]
    EMAIL: 'EMAIL',
    EMAIL_PLACEHOLDER: 'EMAIL_PLACEHOLDER',
    EMAIL_PLACEHOLDER_SHORT: 'EMAIL_PLACEHOLDER_SHORT',
    EMAIL_REQUIRED: 'EMAIL_REQUIRED',
    EMAIL_INVALID: 'EMAIL_INVALID',
    PASSWORD: 'PASSWORD',
    PASSWORD_PLACEHOLDER: 'PASSWORD_PLACEHOLDER',
    INCORRECT_USERNAME_PASSWORD: 'INCORRECT_USERNAME_PASSWORD',
    PASSWORD_PLACEHOLDER_SHORT: 'PASSWORD_PLACEHOLDER_SHORT',
    PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
    PASSWORD_MIN_LENGTH: 'PASSWORD_MIN_LENGTH',
    PASSWORD_MAX_LENGTH: 'PASSWORD_MAX_LENGTH',
    PASSWORD_CONFIRM_MAX_LENGTH: 'PASSWORD_CONFIRM_MAX_LENGTH',
    PASSWORD_INVALID: 'PASSWORD_INVALID',
    FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED',
    FIRST_NAME: 'FIRST_NAME',
    FIRST_NAME_PLACEHOLDER: 'FIRST_NAME_PLACEHOLDER',
    FIRST_NAME_MIN_LENGTH: 'FIRST_NAME_MIN_LENGTH',
    FIRST_NAME_MAX_LENGTH: 'FIRST_NAME_MAX_LENGTH',
    LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED',
    LAST_NAME: 'LAST_NAME',
    LAST_NAME_PLACEHOLDER: 'LAST_NAME_PLACEHOLDER',
    LAST_NAME_MIN_LENGTH: 'LAST_NAME_MIN_LENGTH',
    LAST_NAME_MAX_LENGTH: 'LAST_NAME_MAX_LENGTH',
    //#endregion [Fields]
    
    //#region [Setup layout]
    NEXT: 'NEXT',
    BACK: 'BACK',
    FINISH: 'FINISH',
    SALON_INFO: 'SALON_INFO',
    BRANCH_SETUP: 'BRANCH_SETUP',    
    BUSINESS_HOUR_SETUP: 'BUSINESS_HOUR_SETUP',
    MAIN_BUSINESS_CATEGORY: 'MAIN_BUSINESS_CATEGORY',
    BUSINESS_CATEGORY_DESC: 'BUSINESS_CATEGORY_DESC',
    SERVICE_SETUP: 'SERVICE_SETUP',
    SERVICE_SETUP_TITLE: 'SERVICE_SETUP_TITLE',
    SERVICE_SETUP_DESCRIPTION: 'SERVICE_SETUP_DESCRIPTION',
    EMPLOYEE_SETUP: 'EMPLOYEE_SETUP',
    EMPLOYEE_SETUP_TITLE: 'EMPLOYEE_SETUP_TITLE',
    EMPLOYEE_SETUP_DESCRIPTION: 'EMPLOYEE_SETUP_DESCRIPTION',
    INTRODUCE_TITLE_1: 'INTRODUCE_TITLE_1',
    INTRODUCE_TITLE_2: 'INTRODUCE_TITLE_2',
    INTRODUCE_TITLE_3: 'INTRODUCE_TITLE_3',
    INTRODUCE_TITLE_4: 'INTRODUCE_TITLE_4',
    INTRODUCE_TITLE_5: 'INTRODUCE_TITLE_5',
    INTRODUCE_DESC_1: 'INTRODUCE_DESC_1',
    INTRODUCE_DESC_2: 'INTRODUCE_DESC_2',
    INTRODUCE_DESC_3: 'INTRODUCE_DESC_3',
    INTRODUCE_DESC_4: 'INTRODUCE_DESC_4',
    INTRODUCE_DESC_5: 'INTRODUCE_DESC_5',
    //#endregion [Setup layout]

    //#region [Setup Salon page]
    BUSINESS_NAME: 'BUSINESS_NAME',
    PHONE_NUMBER: 'PHONE_NUMBER',
    WELCOME_TO_ES: 'WELCOME_TO_ES',
    SETUP_YOUR_SALON: 'SETUP_YOUR_SALON',
    BUSINESS_NAME_PLACEHOLDER: 'BUSINESS_NAME_PLACEHOLDER',
    PHONE_PLACEHOLDER: 'PHONE_PLACEHOLDER',
    BUSINESS_IS_REQUIRED: 'BUSINESS_IS_REQUIRED',
    PHONE_NUMBER_IS_INVALID: 'PHONE_NUMBER_IS_INVALID',
    //#endregion [Setup Salon page]

    //#region [Setup branch page]
    BRANCH_SETTING_NAME: 'BRANCH_SETTING_NAME',
    BRANCH_LOGO: 'BRANCH_LOGO',
    BRANCH_NAME: 'BRANCH_NAME',
    BRANCH_NAME_PLACEHOLDER: 'BRANCH_NAME_PLACEHOLDER',
    BRANCH_NAME_IS_REQUIRED: 'BRANCH_NAME_IS_REQUIRED',
    BRANCH_ADDRESS: 'BRANCH_ADDRESS',
    BRANCH_ADDRESS_PLACEHOLDER: 'BRANCH_ADDRESS_PLACEHOLDER',
    BRANCH_ADDRESS_IS_REQUIRED: 'BRANCH_ADDRESS_IS_REQUIRED',
    BRANCH_PHONE: 'BRANCH_PHONE',
    BRANCH_PHONE_PLACEHOLDER: 'BRANCH_PHONE_PLACEHOLDER',
    BRANCH_CITY: 'BRANCH_CITY',
    BRANCH_CITY_PLACEHOLDER: 'BRANCH_CITY_PLACEHOLDER',
    BRANCH_CITY_IS_REQUIRED: 'BRANCH_CITY_IS_REQUIRED',
    BRANCH_STATE: 'BRANCH_STATE',
    BRANCH_STATE_PLACEHOLDER: 'BRANCH_STATE_PLACEHOLDER',
    BRANCH_STATE_IS_REQUIRED: 'BRANCH_STATE_IS_REQUIRED',
    BRANCH_POSTCODE: 'BRANCH_POSTCODE',
    BRANCH_POSTCODE_PLACEHOLDER: 'BRANCH_POSTCODE_PLACEHOLDER',
    BRANCH_POSTCODE_IS_REQUIRED: 'BRANCH_POSTCODE_IS_REQUIRED',
    BRANCH_COUNTRY: 'BRANCH_COUNTRY',
    BRANCH_COUNTRY_PLACEHOLDER: 'BRANCH_COUNTRY_PLACEHOLDER',
    BRANCH_COUNTRY_IS_REQUIRED: 'BRANCH_COUNTRY_IS_REQUIRED',
    BRANCH_CURRENCY: 'BRANCH_CURRENCY',
    BRANCH_CURRENCY_PLACEHOLDER: 'BRANCH_CURRENCY_PLACEHOLDER',
    BRANCH_TAX: 'BRANCH_TAX',
    BRANCH_TAX_PLACEHOLDER: 'BRANCH_TAX_PLACEHOLDER',
    BRANCH_LANGUAGES: 'BRANCH_LANGUAGES',
    BRANCH_LANGUAGES_PLACEHOLDER: 'BRANCH_LANGUAGES_PLACEHOLDER',
    BRANCH_TIMEZONE: 'BRANCH_TIMEZONE',
    BRANCH_TIMEZONE_PLACEHOLDER: 'BRANCH_TIMEZONE_PLACEHOLDER',
    BRANCH_TIMEZONE_IS_REQUIRED: 'BRANCH_TIMEZONE_IS_REQUIRED',
    BRANCH_DATE_TIME_FORMAT: 'BRANCH_DATE_TIME_FORMAT',
    BRANCH_DATE_TIME_FORMAT_PLACEHOLDER: 'BRANCH_DATE_TIME_FORMAT_PLACEHOLDER',
    ADD_FIRST_BRANCH: 'ADD_FIRST_BRANCH',
    BRANCH_SETUP_DESC: 'BRANCH_SETUP_DESC',
    CITY: 'CITY',
    CITY_PLACEHOLDER: 'CITY_PLACEHOLDER',
    STATE: 'STATE',
    STATE_PLACEHOLDER: 'STATE_PLACEHOLDER',
    POSTCODE: 'POSTCODE',
    POSTCODE_PLACEHOLDER: 'POSTCODE_PLACEHOLDER',
    COUNTRY: 'COUNTRY',
    COUNTRY_PLACEHOLDER: 'COUNTRY_PLACEHOLDER',
    STATE_IS_REQUIRED: 'STATE_IS_REQUIRED',
    CITY_IS_REQUIRED: 'CITY_IS_REQUIRED',
    POSTCODE_IS_REQUIRED: 'POSTCODE_IS_REQUIRED',
    COUNTRY_IS_REQUIRED: 'COUNTRY_IS_REQUIRED',
    BRANCH_INFORMATION: 'BRANCH_INFORMATION',
    BRANCH_WORKING_TIME: 'BRANCH_WORKING_TIME',
    BRANCH_UPDATE_TITLE: 'BRANCH_UPDATE_TITLE',
    BRANCH_UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',
    BRANCH_UPDATE_FAILURE: 'BRANCH_UPDATE_FAILURE',
    BRANCH_UPDATE_DESCRIPTION: 'BRANCH_UPDATE_DESCRIPTION',
    BRANCH_CREATE_SUCCESS: 'BRANCH_CREATE_SUCCESS',
    BRANCH_CREATE_FAILURE: 'BRANCH_CREATE_FAILURE',
    BRANCH_CREATE_DESCRIPTION: 'BRANCH_CREATE_DESCRIPTION',
    BRANCH_CHANGE_SUCCESS: 'BRANCH_CHANGE_SUCCESS',
    BRANCH_CHANGE_FAILURE: 'BRANCH_CHANGE_FAILURE',
    //#endregion [Setup branch page]

    //#region [Setup BusinessHour page]
    BUSINESS_HOUR_NAME: 'BUSINESS_HOUR_NAME',
    //#endregion [Setup BusinessHour page]

    //#region [Setup service page]
    USE_SAMPLE: 'USE_SAMPLE',
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    CREATE_CATEGORY_TITLE: 'CREATE_CATEGORY_TITLE',
    UPDATE_CATEGORY_TITLE: 'UPDATE_CATEGORY_TITLE',
    CREATE_SERVICE: 'CREATE_SERVICE',
    CREATE_SERVICE_TITLE: 'CREATE_SERVICE_TITLE',
    UPDATE_SERVICE_TITLE: 'UPDATE_SERVICE_TITLE',
    DELETE_SERVICE_TITLE: 'DELETE_SERVICE_TITLE',
    DELETE_SERVICE_CONFIRM_MESSAGE: 'DELETE_SERVICE_CONFIRM_MESSAGE',
    CREATE_SERVICE_SUCCESS: 'CREATE_SERVICE_SUCCESS',
    UPDATE_SERVICE_SUCCESS: 'UPDATE_SERVICE_SUCCESS',
    DELETE_SERVICE_SUCCESS: 'DELETE_SERVICE_SUCCESS',
    NO_SERVICE: 'NO_SERVICE',
    CATEGORY_NAME_REQUIRED: 'CATEGORY_NAME_REQUIRED',
    MAX_LENGTH_NAME: 'MAX_LENGTH_NAME',
    CATEGORY: 'CATEGORY',
    SERVICE_CATEGORY_REQUIRED: 'SERVICE_CATEGORY_REQUIRED',
    SERVICE_CATEGORY_PLACEHOLDER: 'SERVICE_CATEGORY_PLACEHOLDER',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DURATION_MINUTE: 'DURATION_MINUTE',
    SERVICE_DURATION_REQUIRED: 'SERVICE_DURATION_REQUIRED',
    SERVICE_DURATION_INVALID: 'SERVICE_DURATION_INVALID',
    ENTER_DURATION: 'ENTER_DURATION',
    DESCRIPTION: 'DESCRIPTION',
    PRICE: 'PRICE',
    ENTER_PRICE: 'ENTER_PRICE',
    PRICE_REQUIRED: 'PRICE_REQUIRED',
    PRICE_SERVICE_INVALID: 'PRICE_SERVICE_INVALID',
    TAX: 'TAX',
    SELECT_TAX: 'SELECT_TAX',
    ENTER_DESCRIPTION: 'ENTER_DESCRIPTION',
    MAX_LENGTH_DESCRIPTION: 'MAX_LENGTH_DESCRIPTION',
    //#endregion [Setup service page]

    //#region [Setup employee page]
    NO_EMPLOYEE: 'NO_EMPLOYEE',
    GENERAL_INFO: 'GENERAL_INFO',
    CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
    MAX_LENGTH_ADDRESS: 'MAX_LENGTH_ADDRESS',
    UPLOAD_PHOTO: 'UPLOAD_PHOTO',
    JOB_INFO: 'JOB_INFO',
    JOB_TYPE: 'JOB_TYPE',
    CREATE_JOB_TYPE: 'CREATE_JOB_TYPE',
    SELECT_JOB_TYPE: 'SELECT_JOB_TYPE',
    START_DATE: 'START_DATE',
    START_DATE_PLACEHOLDER: 'START_DATE_PLACEHOLDER',
    STATUS: 'STATUS',
    STATUS_PLACEHOLDER: 'STATUS_PLACEHOLDER',
    UPDATE_EMPLOYEE_TITLE: 'UPDATE_EMPLOYEE_TITLE',
    CREATE_EMPLOYEE_TITLE: 'CREATE_EMPLOYEE_TITLE',
    DELETE_EMPLOYEE_TITLE: 'DELETE_EMPLOYEE_TITLE',
    DELETE_EMPLOYEE_CONFIRM_MESSAGE: 'DELETE_EMPLOYEE_CONFIRM_MESSAGE',
    CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
    UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    BRANCH_CREATE_SUCCESS_ALERT: 'BRANCH_CREATE_SUCCESS_ALERT',
    BRANCH_CREATE_DESCRIPTION_ALERT: 'BRANCH_CREATE_DESCRIPTION_ALERT',
    EXPLORE: 'EXPLORE',
    //#endregion [Setup employee page]

    //#region [Message ID]
    NM_AU_01: 'NM_AU_01',
    NM_AU_02: 'NM_AU_02',
    //#endregion [Message ID]

    //#region [Validation Messages]
    VAL_AU_01: 'VAL_AU_01',
    VAL_AU_02: 'VAL_AU_02',
    VAL_AU_03: 'VAL_AU_03',
    VAL_AU_04: 'VAL_AU_04',
    VAL_AU_05: 'VAL_AU_05',
    VAL_AU_06: 'VAL_AU_06',
    VAL_AU_07: 'VAL_AU_07',
    VAL_AU_08: 'VAL_AU_08',
    VAL_AU_09: 'VAL_AU_09',
    VAL_AU_10: 'VAL_AU_10',
    VAL_AU_11: 'VAL_AU_11',
    VAL_AU_12: 'VAL_AU_12',
    VAL_AU_13: 'VAL_AU_13',
    VAL_AU_14: 'VAL_AU_14',
    VAL_AU_15: 'VAL_AU_15',
    VAL_AU_16: 'VAL_AU_16',
    VAL_AU_17: 'VAL_AU_17',
    VAL_AU_18: 'VAL_AU_18',
    //#endregion [Validation Messages]
};

export type TranslateResources = Record<keyof typeof translateCodes, string>;