
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { EmployeeInitCreate } from '@esg/business-account';
import { translateCodes } from '@/locales';

export const EmployeeDeleteConfirm = ({ item }: { item: EmployeeInitCreate}) => {
    
    return (
        <Spacer top='xs'>
            <Paragraph fontSize={18} fontWeight={700}>
                <Text translateCode={translateCodes.DELETE_EMPLOYEE_TITLE} />
            </Paragraph>
            <Spacer type='margin' top={8} />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_EMPLOYEE_CONFIRM_MESSAGE} />:
                <Text fontWeight={700}> 
                    {' '+ item.firstName + ' ' + item.lastName}
                </Text>
            </Paragraph>
        </Spacer>
    );
};
