import { Route, Routes } from 'react-router-dom';

import { Authorize } from './authorize';

export const Users = () => {
    return (
        <Routes>
            <Route path='/authorize' Component={Authorize} />
        </Routes>
    );
};
