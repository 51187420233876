const authPath = '/auth';

export const authPaths = {
    auth: authPath,
    login: `${authPath}/login`,
    register: `${authPath}/register`,
    emailCheck: `${authPath}/email-check`,
    emailCheckResetPw: `${authPath}/email-check-verify`,
    forgotPassword: `${authPath}/forgot-password`,
    resetPassword: `${authPath}/reset-password`,
    verifyEmail: `${authPath}/verify-email`,
    resetPasswordSuccess: `${authPath}/reset-password-success`,
};
