import { NotFound } from '@esg/ui';
import { Route, Routes } from 'react-router-dom';

import { EmailCheckResetPassword } from './email-check-reset-password';
import { EmailCheckVerify } from './email-check-verify';
import { ExternalCallback } from './external';
import { ForgotPassword } from './forgot-password';
import { Login } from './login';
import { Register } from './register';
import { ResetPassword } from './reset-password';
import { ResetPasswordSuccess } from './reset-password-success';
import { VerifyEmail } from './verify-email';

import { InviteUser } from './invite-user';
import { AuthLayout } from '@/layouts/auth';

export const Auth = () => {
    return (
        <Routes>
            <Route Component={AuthLayout}>
                <Route path='/external/callback' Component={ExternalCallback} />
                <Route path='/login' Component={Login} />
                <Route path='/register' Component={Register} />
                <Route path='/forgot-password' Component={ForgotPassword} />
                <Route path='/email-check-verify' Component={EmailCheckVerify} />
                <Route path='/email-check' Component={EmailCheckResetPassword} />
                <Route path='/verify-reset-password' Component={ResetPassword} />
                <Route path='/reset-password-success' Component={ResetPasswordSuccess} />
                <Route path='/verify-email' Component={VerifyEmail} />
                <Route path='/verify-invite' Component={InviteUser} />
            </Route>
            <Route path='*' Component={NotFound} />
        </Routes>
    );
};
