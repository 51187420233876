import { accountApi } from '@esg/business-account';
import {
    AppButton,
    FlexBox,
    FormBase,
    InputField,
    useHttpCommand,
    RefMethodFormType
} from '@esg/ui';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {  viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { authPaths, authRules } from '../../@shared';

import { translateCodes } from '@/locales';

interface RegisterFormValues {
    readonly email: string;
    readonly password: string;
    readonly firstName: string;
    readonly lastName: string;
}

export const RegisterForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef : RefMethodFormType = useRef(null);

    const { mutateAsync, isPending } = useHttpCommand(accountApi.register, {
        showNotificationError: false,
    });

    const onSubmit = React.useCallback(
        async (values: RegisterFormValues) => {
            await mutateAsync(
                {
                    body: {
                        email: values.email,
                        password: values.password,
                        firstName: values.firstName,
                        lastName: values.lastName,
                    },
                },
                {
                    onError() {
                        formRef.current?.setError('email', {
                            type: 'validate',
                            message: t(translateCodes.EMAIL_ALREADY_EXISTS),
                        });
                        
                    },
                    onSuccess(response) {
                        viewRequests.showNotification.send({
                            type: 'success',
                            title: t(translateCodes.NM_AU_02),
                            message: '',
                        });
                        navigate(authPaths.emailCheckResetPw, {
                            state: {
                                email: response.email,
                                userId: response.id,
                                token: response.token,
                            }
                        });
                        
                    },
                }
            );
        },
        [mutateAsync, navigate, t]
    );

    return (
        <FormBase onSubmit={onSubmit} formRef={formRef}>
            <FlexBox direction='column' gap={20}>
                <FlexBox gap={16} justifyContent='space-between'>
                    <InputField
                        name='firstName'
                        label={translateCodes.FIRST_NAME}
                        placeholder={translateCodes.FIRST_NAME_PLACEHOLDER}
                        rules={authRules.firstName}
                    />
                    <InputField
                        name='lastName'
                        label={translateCodes.LAST_NAME}
                        placeholder={translateCodes.LAST_NAME_PLACEHOLDER}
                        rules={authRules.lastName}
                    />
                </FlexBox>
                <FlexBox direction='column'>
                    <InputField
                        name='email'
                        label={translateCodes.EMAIL}
                        placeholder={translateCodes.EMAIL_PLACEHOLDER_SHORT}
                        rules={authRules.email}
                    />
                </FlexBox>
                <FlexBox alignItems='end'>
                    <InputField
                        type='password'
                        name='password'
                        label={translateCodes.PASSWORD}
                        placeholder={translateCodes.PASSWORD_PLACEHOLDER_SHORT}
                        rules={authRules.password}
                        tooltipContent={translateCodes.VALIDATE_PASSWORD}
                    />
                </FlexBox>
                <AppButton
                    loading={isPending}
                    type='primary'
                    htmlType='submit'
                    translateCode={translateCodes.GET_STARTED}
                />
            </FlexBox>
        </FormBase>
    );
};
