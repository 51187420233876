import React from 'react';
import { IconProps } from '../../Types';

export const IconMassage = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_43282)'>
                <path
                    d='M20.9844 24.1404H18.4805V24.4992C18.4805 25.4508 17.7106 26.2222 16.761 26.2222C15.8113 26.2222 15.0414 25.4508 15.0414 24.4992V24.1404H12.5375C9.23559 24.1404 6.55884 26.811 6.55884 30.1054V31.6368H26.9631V30.1054C26.9631 26.811 24.2863 24.1404 20.9844 24.1404Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.0415 22.2199C15.3867 22.2199 15.6665 22.4997 15.6665 22.8449V24.4992C15.6665 25.1068 16.1577 25.5972 16.7611 25.5972C17.3644 25.5972 17.8556 25.1068 17.8556 24.4992V22.8449C17.8556 22.4997 18.1355 22.2199 18.4806 22.2199C18.8258 22.2199 19.1056 22.4997 19.1056 22.8449V24.4992C19.1056 25.7948 18.0571 26.8472 16.7611 26.8472C15.465 26.8472 14.4165 25.7948 14.4165 24.4992V22.8449C14.4165 22.4997 14.6963 22.2199 15.0415 22.2199Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23.7091 11.106C23.8003 10.773 24.1441 10.5771 24.477 10.6682C25.6366 10.9858 26.4974 12.0379 26.4974 13.2983C26.4974 13.542 26.465 13.7794 26.4037 14.0058C26.3135 14.339 25.9702 14.536 25.6371 14.4457C25.3039 14.3555 25.1069 14.0123 25.1972 13.6791C25.2298 13.5584 25.2474 13.4309 25.2474 13.2983C25.2474 12.6255 24.7871 12.0492 24.1469 11.8738C23.8139 11.7827 23.618 11.4389 23.7091 11.106Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.33956 15.5899C9.68332 15.5587 9.98729 15.8121 10.0185 16.1558C10.3302 19.5915 13.2641 22.2199 16.7354 22.2199H16.7864C20.2469 22.2199 23.1735 19.6077 23.5004 16.1878C23.5332 15.8442 23.8384 15.5922 24.182 15.6251C24.5256 15.6579 24.7775 15.9631 24.7447 16.3067C24.355 20.3835 20.8755 23.4699 16.7864 23.4699H16.7354C12.6337 23.4699 9.14522 20.3643 8.77359 16.2688C8.7424 15.925 8.99579 15.6211 9.33956 15.5899Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.80185 11.1058C9.89463 11.4383 9.70031 11.783 9.36783 11.8758C8.73129 12.0534 8.27441 12.6279 8.27441 13.2983C8.27441 13.4365 8.2936 13.5695 8.32915 13.6952C8.42306 14.0274 8.22993 14.3728 7.89778 14.4667C7.56562 14.5606 7.22022 14.3675 7.1263 14.0353C7.05985 13.8003 7.02441 13.5529 7.02441 13.2983C7.02441 12.0426 7.87879 10.9935 9.03187 10.6718C9.36435 10.579 9.70908 10.7733 9.80185 11.1058Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.9195 1.77917C16.4661 1.75654 15.4402 1.8596 15.0613 1.94437C11.8576 2.66125 9.63668 5.48377 9.58659 8.60715C9.58106 8.95229 9.29678 9.22758 8.95165 9.22205C8.60652 9.21652 8.33122 8.93224 8.33675 8.58711C8.39604 4.88987 11.0172 1.56839 14.7884 0.724527L14.7884 0.72452C15.2644 0.61804 16.4035 0.501851 16.9818 0.53072C17.3266 0.547929 17.5921 0.841353 17.5749 1.1861C17.5577 1.53085 17.2643 1.79637 16.9195 1.77917Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.4385 6.91376C17.4721 7.25729 17.2209 7.56303 16.8773 7.59663C16.0836 7.67425 15.399 7.76453 14.9112 7.86714L14.9112 7.86715C13.304 8.2052 11.9398 9.09045 10.962 10.2706C10.7418 10.5364 10.3478 10.5734 10.082 10.3532C9.81619 10.133 9.77923 9.73897 9.99945 9.47317C11.1447 8.09086 12.7512 7.04411 14.6539 6.64392C15.2101 6.52691 15.9492 6.43143 16.7556 6.35256C17.0992 6.31896 17.4049 6.57022 17.4385 6.91376Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.6644 1.47907C15.3129 0.842915 16.2302 0.470679 17.354 0.532786L17.3195 1.15683L17.354 0.532786C17.8112 0.558052 18.2723 0.621196 18.7334 0.724348L18.7334 0.724355C22.5104 1.56956 25.134 4.90008 25.1853 8.60437C25.1901 8.94951 24.9141 9.23318 24.569 9.23796C24.2238 9.24274 23.9402 8.96682 23.9354 8.62167C23.8921 5.49246 21.6692 2.66223 18.4604 1.94419C18.0666 1.85609 17.6738 1.80237 17.285 1.78088L17.285 1.78088C16.4942 1.73718 15.9249 1.99352 15.5397 2.37142C15.1443 2.75925 14.9085 3.30996 14.8666 3.89482C14.8246 4.47961 14.9791 5.06176 15.3163 5.50757C15.6451 5.94233 16.1734 6.28288 16.9617 6.35999L16.9008 6.98202L16.9617 6.35999C17.7681 6.43886 18.5072 6.53434 19.0634 6.65134C20.9864 7.05581 22.6069 8.1207 23.7544 9.52498C23.9728 9.79227 23.9332 10.186 23.6659 10.4044C23.3987 10.6228 23.0049 10.5832 22.7865 10.3159C21.8067 9.11684 20.4305 8.21623 18.8061 7.87458L18.8061 7.87457C18.3183 7.77195 17.6337 7.68168 16.84 7.60405L16.84 7.60405C15.7201 7.4945 14.868 6.98712 14.3193 6.2616C13.7789 5.54712 13.5587 4.65663 13.6198 3.80533C13.6809 2.95411 14.026 2.10529 14.6644 1.47907Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.4006 14.7139C12.643 14.4682 13.0387 14.4656 13.2844 14.708C13.6065 15.0258 14.132 15.0258 14.454 14.708C14.6997 14.4656 15.0955 14.4682 15.3379 14.7139C15.5803 14.9596 15.5777 15.3553 15.332 15.5978C14.5232 16.3959 13.2153 16.3959 12.4065 15.5978C12.1608 15.3553 12.1581 14.9596 12.4006 14.7139Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.184 14.7139C18.4265 14.4682 18.8222 14.4656 19.0679 14.708C19.3899 15.0258 19.9154 15.0258 20.2375 14.708C20.4832 14.4656 20.8789 14.4682 21.1214 14.7139C21.3638 14.9596 21.3612 15.3553 21.1155 15.5978C20.3066 16.3959 18.9987 16.3959 18.1899 15.5978C17.9442 15.3553 17.9416 14.9596 18.184 14.7139Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.4086 12.8932C20.9296 12.4205 20.1497 12.4205 19.6707 12.8932C19.425 13.1357 19.0293 13.133 18.7868 12.8874C18.5444 12.6417 18.547 12.246 18.7927 12.0035C19.7584 11.0504 21.3209 11.0504 22.2866 12.0035C22.5323 12.246 22.5349 12.6417 22.2925 12.8874C22.05 13.133 21.6543 13.1357 21.4086 12.8932Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.8515 12.8932C13.3725 12.4205 12.5926 12.4205 12.1136 12.8932C11.8679 13.1357 11.4722 13.133 11.2297 12.8874C10.9872 12.6417 10.9899 12.246 11.2356 12.0035C12.2013 11.0504 13.7637 11.0504 14.7295 12.0035C14.9752 12.246 14.9778 12.6417 14.7354 12.8874C14.4929 13.133 14.0972 13.1357 13.8515 12.8932Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.581 18.2904C14.8235 18.0447 15.2192 18.0421 15.4649 18.2846C16.1797 18.9899 17.3421 18.9899 18.0569 18.2846C18.3026 18.0421 18.6983 18.0447 18.9407 18.2904C19.1832 18.5361 19.1806 18.9318 18.9349 19.1743C17.7333 20.36 15.7885 20.36 14.5869 19.1743C14.3412 18.9318 14.3386 18.5361 14.581 18.2904Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M27.6595 0.988342C27.6595 0.643164 27.9393 0.363342 28.2845 0.363342H32.1249C32.4701 0.363342 32.7499 0.643164 32.7499 0.988342V9.48478C32.7499 11.4785 31.8175 13.3575 30.23 14.561L26.5457 17.3541C25.5577 18.1031 24.1327 17.7496 23.6071 16.6274C23.2111 15.7821 23.469 14.7737 24.2233 14.2233C24.2233 14.2233 24.2233 14.2233 24.2233 14.2233L27.4759 11.85L27.5265 10.6748L24.6719 11.8095C24.6719 11.8095 24.6719 11.8095 24.6719 11.8095C23.8524 12.1352 22.9281 11.7137 22.6343 10.8833C22.4306 10.3073 22.5825 9.66491 23.0233 9.24157L24.8387 7.49834C25.2865 7.06839 25.883 6.82809 26.5038 6.82809C27.1409 6.82809 27.6595 6.31022 27.6595 5.66878V0.988342ZM28.9095 1.61334V5.66878C28.9095 6.99821 27.8336 8.07809 26.5038 8.07809C26.2061 8.07809 25.9198 8.1933 25.7045 8.39997M25.7045 8.39997L23.8892 10.1431C23.8892 10.1431 23.8892 10.1431 23.8892 10.1431C23.8028 10.2262 23.7726 10.3527 23.8128 10.4664C23.8706 10.63 24.0513 10.7111 24.2101 10.6479L27.9615 9.15679C28.1584 9.07852 28.3816 9.10536 28.5544 9.2281C28.7271 9.35083 28.8259 9.55275 28.8168 9.76446L28.7118 12.2043C28.7036 12.3943 28.6094 12.5703 28.4558 12.6823L24.9601 15.2331C24.6898 15.4303 24.5965 15.7928 24.7391 16.0972C24.9278 16.5002 25.4377 16.6255 25.7906 16.358L29.4749 13.5649C30.7501 12.5981 31.4999 11.0879 31.4999 9.48478V1.61334H28.9095'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M0.75 0.988342C0.75 0.643164 1.02982 0.363342 1.375 0.363342H5.21544C5.56062 0.363342 5.84044 0.643164 5.84044 0.988342V5.66878C5.84044 6.31022 6.35905 6.82809 6.99619 6.82809C7.61693 6.82809 8.21344 7.06839 8.6612 7.49834L8.6612 7.49834L10.4766 9.24153L10.4766 9.24157C10.9174 9.66491 11.0694 10.3073 10.8656 10.8833C10.5718 11.7137 9.64751 12.1352 8.82808 11.8095C8.82807 11.8095 8.82807 11.8095 8.82806 11.8095L5.97348 10.6748L6.02405 11.85L9.27664 14.2233C9.27665 14.2233 9.27665 14.2233 9.27665 14.2233C10.031 14.7737 10.2888 15.7821 9.89287 16.6274C9.36726 17.7496 7.94224 18.1031 6.95424 17.3541L7.32115 16.8701L6.95423 17.3541L3.26994 14.561C1.68245 13.3576 0.75 11.4786 0.75 9.48484V0.988342ZM2 1.61334V9.48484C2 11.088 2.7498 12.5981 4.02506 13.5648L4.02508 13.5649L7.70939 16.358C8.06226 16.6255 8.57211 16.5002 8.76088 16.0972L8.76089 16.0972C8.90344 15.7928 8.81017 15.4303 8.53986 15.2331L8.53985 15.2331L5.04416 12.6823C4.89054 12.5703 4.79632 12.3943 4.78814 12.2043L4.68314 9.76446C4.67403 9.55275 4.77282 9.35083 4.94557 9.2281C5.11832 9.10536 5.3415 9.07852 5.53842 9.15679L9.2898 10.6479L9.28981 10.6479C9.44862 10.7111 9.6293 10.63 9.68715 10.4664L10.2764 10.6748L9.68716 10.4664C9.72739 10.3527 9.69722 10.2262 9.61076 10.1431L7.79543 8.39997C7.79543 8.39997 7.79543 8.39997 7.79542 8.39997C7.58019 8.1933 7.29381 8.07809 6.99619 8.07809C5.66633 8.07809 4.59044 6.99821 4.59044 5.66878V1.61334H2Z'
                    fill='#0C6FF3'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_43282'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.75)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
