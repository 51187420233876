import { useCallback, useEffect, useState } from 'react';

import { LayoutSize } from '../Types';
import { screenUtil } from '../utils';

export const useScreenSize = () => {
    const [layoutSize, setLayoutSize] = useState<LayoutSize>({
        size: screenUtil.getScreenSize(window.innerWidth),
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const onLayout = useCallback(
        () => {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            setLayoutSize({
                size: screenUtil.getScreenSize(windowWidth),
                width: windowWidth,
                height: windowHeight,
            });
        },
        [setLayoutSize]
    );

    useEffect(() => {
        return window.addEventListener('resize', onLayout);
    }, [onLayout]);

    return layoutSize;
};