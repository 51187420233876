import './ServiceSetup.scss';
import React, { useCallback } from 'react';
import { AppButton, Block, FlexBox, globalModalState, SwitchField } from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { ServiceCategoryInitCreate, ServiceInitCreate } from '@esg/business-account';
import { PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { sampleCategoryData, sampleServiceData } from '../@shared/dataSample';
import { ServiceCategoryItem } from './children/ServiceCategoryItem';
import CategoryForm from './children/CategoryForm';
import ServiceSetupForm from './children/ServiceSetupForm';
import { translateCodes } from '@/locales';

export const ServiceSetup = () => {
    const { t } = useTranslation();
    const { setValue, watch} = useFormContext();
    const [ globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    
    //init default data
    const categories: ServiceCategoryInitCreate[] = watch('serviceCategories');    
    const services: ServiceInitCreate[] = watch('services');
    const useSampleService = watch('useSampleService');

    const onCancel = useCallback(()=>{
        resetGlobalModal?.();
    },[resetGlobalModal]);

    //#region [Setup service category]
    const onUpdateCategory = useCallback((value: ServiceCategoryInitCreate)=>{
        const updatedCategoryList = categories.map((o) => 
            o.tempId == value.tempId ? value : o
        );
        setValue('serviceCategories', updatedCategoryList);
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.UPDATE_CATEGORY_SUCCESS),
            message: '',
        });
    },[categories, setValue, t]);

    const onCreateCategory = useCallback((value: ServiceCategoryInitCreate)=>{
        const lastCategory = categories.findLast(o=>o);
        const newId = lastCategory ? lastCategory.tempId + 1 : 0;
            
        const newCategory: ServiceCategoryInitCreate = {
            tempId: value.tempId ?? newId,
            type: value.type ?? 'added',
            name: value.name
        };
        
        const updatedCategories = [...categories, newCategory];
        setValue('serviceCategories', updatedCategories);
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.CREATE_CATEGORY_SUCCESS),
            message: '',
        });
    },[categories, setValue, t]);

    const onSubmitCategory = useCallback((value: ServiceCategoryInitCreate)=>{
        const isUpdate = value.tempId != undefined && value.tempId >= 0; 
        if (isUpdate) {
            onUpdateCategory(value);
        }else{
            onCreateCategory(value);
        }
        resetGlobalModal?.();
    },[onCreateCategory, onUpdateCategory, resetGlobalModal]); 

    const handlerCategory = useCallback((id?: number) => {
        const isUpdate = id !== undefined && id >= 0;
        const currentCategory = categories.find(o=>o.tempId == id);
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            footer: null,
            titleTransCode: isUpdate ? translateCodes.UPDATE_CATEGORY_TITLE : translateCodes.CREATE_CATEGORY_TITLE,
            content: <CategoryForm
                defaultData={isUpdate ? currentCategory : undefined}
                onCancel={onCancel}
                onSubmit={onSubmitCategory}
            />
        });
    }, [categories, globalModal, onCancel, onSubmitCategory, setGlobalModal]);
    //#endregion [Setup service category]

    //#region [Setup service] 
    const onDeleteService = useCallback((id?: number) => {
        const updatedCategoryList = services.filter(o=>o.id != id);
        setValue('services', updatedCategoryList);
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.DELETE_SERVICE_SUCCESS),
            message: '',
        });
    }, [services, setValue, t]);

    const onSubmitService = useCallback((value: ServiceInitCreate)=>{   
        const isUpdate = value.id != null;

        if (isUpdate) {
            const updatedService = services?.map(o => 
                o.id ===  value.id ? value : o
            );
            setValue('services', updatedService);
            resetGlobalModal?.();
            viewRequests.showNotification.send({
                type: 'success',
                title: t(translateCodes.UPDATE_SERVICE_SUCCESS),
                message: '',
            });
            return;
        }

        const lastService = services.findLast(o => o);
        const newId = lastService ? lastService.id + 1 : 0;
        const newService: ServiceInitCreate = {
            ...value,
            id: newId,
            type: 'added',
        };
        setValue('services', [...services, newService]);
        resetGlobalModal?.();
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.CREATE_SERVICE_SUCCESS),
            message: '',
        });
    },[resetGlobalModal, services, setValue, t]);

    const handleService = useCallback((categoryId?: number, id?: number) => {
        const currentService = services.find(o=>o.id == id);
        // const currentService = currentCategory?.services.find(o=>o.id == serviceIndex);
        const isUpdate = id !== undefined && id >= 0;
        
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            footer: null,
            titleTransCode: isUpdate ? translateCodes.UPDATE_SERVICE_TITLE : translateCodes.CREATE_SERVICE_TITLE,
            content: <ServiceSetupForm
                categoryId={categoryId}
                defaultData={currentService}
                onCancel={onCancel}
                isUpdate={isUpdate}
                handleCreateCategory={onCreateCategory}
                onSubmitService={onSubmitService}
                categories={categories}
                watch={watch}
            />
        });
    }, [categories, globalModal, onCancel, onCreateCategory, onSubmitService, services, setGlobalModal, watch]);
    //#endregion [Setup service]

    const isDisableUseSample = categories.some(o=> o.type === 'added');

    return (
        <FlexBox direction='column' gap={32}>
            <SwitchField
                name='useSampleService'
                directionLabel='row-reverse'
                label={translateCodes.USE_SAMPLE}
                onChange={(value) => {
                    setValue('serviceCategories', value ? sampleCategoryData : []);
                    setValue('services', value ? sampleServiceData : []);
                }}
                disabled={isDisableUseSample}
                defaultChecked={useSampleService}
                className='use-sample-switch'
            />
            <Block className='main-content-category' >
                {categories.length == 0 ? (
                    <ServiceCategoryItem 
                        watch={watch}
                        showButton={!useSampleService}
                        onCreateService={handleService}
                        onCategory={handlerCategory}
                        onDeleteService={onDeleteService}
                        onEditService={handleService}
                    /> 
                ):(
                    <FlexBox direction='column' gap={32}>
                        {categories.map((o : ServiceCategoryInitCreate, index) => (
                            <ServiceCategoryItem 
                                key={index}
                                watch={watch}
                                dataCategory={o}
                                dataService={services}
                                onCreateService={handleService}
                                onCategory={handlerCategory}
                                showButton={!useSampleService}
                                onDeleteService={onDeleteService}
                                onEditService={handleService}
                            />
                        ))}
                    </FlexBox>
                )}
            </Block>
            <FlexBox>
                {!useSampleService && <AppButton
                    translateCode={t(translateCodes.CREATE_CATEGORY)}
                    icon={<PlusCircleFilled />}
                    type='link'
                    onClick={()=>handlerCategory()}
                    padding={0}
                />}
            </FlexBox>
        </FlexBox>
    );
};

