import { accountApi } from '@esg/business-account';
import {
    AppButton,
    FormBase,
    InputField,
    RefMethodFormType,
    Spacer
    , useHttpCommand
} from '@esg/ui';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { authPaths, authRules } from '../../@shared';

import { translateCodes } from '@/locales';

export const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef : RefMethodFormType = useRef(null);

    const { mutateAsync, isPending } = useHttpCommand(
        accountApi.forgotPassword, {
            showNotificationError: false
        }
    );
    const onSubmit = React.useCallback(
        async (data: { email: string }) => {
            await mutateAsync(
                {
                    body: {
                        email: data.email,
                    },
                },
                {
                    onError() {
                        formRef.current?.setError('email', {
                            type: 'validate',
                            message: t(translateCodes.INVALID_EMAIL_ENTERED),
                        });
                    },
                    onSuccess() {
                        navigate(authPaths.emailCheck, { state: data.email });
                    },
                }
            );
        },
        [mutateAsync, navigate, t]
    );

    return (
        <FormBase onSubmit={onSubmit} formRef={formRef}>
            <InputField
                name='email'
                label={translateCodes.EMAIL}
                placeholder={translateCodes.EMAIL_PLACEHOLDER_SHORT}
                rules={authRules.email}
            />
            <Spacer bottom='md' />
            <AppButton
                loading={isPending}
                htmlType='submit'
                type='primary'
                width='100%'
                translateCode={translateCodes.RESET_PASSWORD}
            />
        </FormBase>
    );
};
