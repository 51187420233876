import React from 'react';
import { IconProps } from '../../Types';

export const IconTanningStudio = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <mask
                id='mask0_4955_43482'
                style={{ maskType: 'luminance' }}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='33'
                height='32'
            >
                <path
                    d='M0.75 1.90735e-06H32.75V32H0.75V1.90735e-06Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_4955_43482)'>
                <path
                    d='M23.3609 10.1873C23.3609 13.8333 20.4011 16.789 16.75 16.789C13.0989 16.789 10.1392 13.8333 10.1392 10.1873C10.1392 6.54128 13.0989 3.58553 16.75 3.58553C20.4011 3.58553 23.3609 6.54128 23.3609 10.1873Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.75 2.0318C17.0952 2.0318 17.375 1.75198 17.375 1.4068V0.624987C17.375 0.279809 17.0952 -1.28746e-05 16.75 -1.28746e-05C16.4048 -1.28746e-05 16.125 0.279809 16.125 0.624987V1.4068C16.125 1.75198 16.4048 2.0318 16.75 2.0318Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.75 20.3745C17.0952 20.3745 17.375 20.0947 17.375 19.7495V18.9677C17.375 18.6226 17.0952 18.3427 16.75 18.3427C16.4048 18.3427 16.125 18.6226 16.125 18.9677V19.7495C16.125 20.0947 16.4048 20.3745 16.75 20.3745Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24.9175 10.1873C24.9175 10.5325 25.1973 10.8123 25.5425 10.8123H26.3254C26.6705 10.8123 26.9504 10.5325 26.9504 10.1873C26.9504 9.84211 26.6705 9.56229 26.3254 9.56229H25.5425C25.1973 9.56229 24.9175 9.84211 24.9175 10.1873Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.54932 10.1873C6.54932 10.5325 6.82914 10.8123 7.17432 10.8123H7.95719C8.30237 10.8123 8.58219 10.5325 8.58219 10.1873C8.58219 9.84211 8.30237 9.56229 7.95719 9.56229H7.17432C6.82914 9.56229 6.54932 9.84211 6.54932 10.1873Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M22.525 4.42016C22.769 4.6644 23.1647 4.66467 23.4089 4.42076L23.9625 3.86794C24.2067 3.62403 24.207 3.2283 23.9631 2.98406C23.7192 2.73982 23.3234 2.73955 23.0792 2.98346L22.5256 3.53627C22.2814 3.78019 22.2811 4.17591 22.525 4.42016Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.53674 17.3904C9.78064 17.6347 10.1764 17.635 10.4206 17.3911L10.9742 16.8382C11.2185 16.5943 11.2188 16.1986 10.9749 15.9544C10.731 15.7101 10.3353 15.7098 10.091 15.9537L9.53739 16.5065C9.29313 16.7504 9.29284 17.1462 9.53674 17.3904Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23.9631 17.3905C24.207 17.1462 24.2067 16.7505 23.9625 16.5066L23.4089 15.9538C23.1647 15.7099 22.769 15.7101 22.525 15.9544C22.2811 16.1986 22.2814 16.5943 22.5256 16.8383L23.0792 17.3911C23.3234 17.635 23.7192 17.6347 23.9631 17.3905Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.9749 4.42016C11.2188 4.17591 11.2185 3.78018 10.9742 3.53628L10.4206 2.98347C10.1764 2.73957 9.78064 2.73986 9.53674 2.98412C9.29284 3.22837 9.29313 3.6241 9.53739 3.868L10.091 4.42081C10.3353 4.66471 10.731 4.66442 10.9749 4.42016Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.125 31.375C16.125 31.7202 16.4048 32 16.75 32H20.2732C23.2379 32 25.6412 29.5967 25.6412 26.632V21.8038C25.6412 21.4586 25.3614 21.1788 25.0162 21.1788H21.493C18.5283 21.1788 16.125 23.5821 16.125 26.5469V31.375ZM17.375 30.75V26.5469C17.375 24.2725 19.2187 22.4288 21.493 22.4288H24.3912V26.632C24.3912 28.9063 22.5475 30.75 20.2732 30.75H17.375Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.4423 31.7425C16.702 31.9698 17.0969 31.9436 17.3243 31.6839L21.9777 26.3688C22.2051 26.1091 22.1789 25.7142 21.9191 25.4868C21.6594 25.2595 21.2646 25.2857 21.0372 25.5454L16.3838 30.8605C16.1564 31.1202 16.1826 31.5151 16.4423 31.7425Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.2264 30.75C10.9521 30.75 9.1084 28.9063 9.1084 26.632V22.4288H12.0066C14.2809 22.4288 16.1246 24.2725 16.1246 26.5469V30.75H13.2264ZM7.8584 26.632C7.8584 29.5967 10.2617 32 13.2264 32H16.7496C17.0948 32 17.3746 31.7202 17.3746 31.375V26.5469C17.3746 23.5821 14.9713 21.1788 12.0066 21.1788H8.4834C8.13822 21.1788 7.8584 21.4586 7.8584 21.8038V26.632Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.0573 31.7425C17.317 31.5151 17.3433 31.1202 17.1159 30.8605L12.4624 25.5454C12.2351 25.2857 11.8402 25.2595 11.5805 25.4868C11.3208 25.7142 11.2946 26.1091 11.522 26.3688L16.1754 31.6839C16.4028 31.9436 16.7976 31.9698 17.0573 31.7425Z'
                    fill='#0C6FF3'
                />
            </g>
        </svg>
    );
};
