import { httpUtil } from '@esg/framework';

import { env } from '../../config';

interface ResetPasswordRequest {
    readonly body: {
        email: string;
    };
}

export const resentEmailVerify = httpUtil.createHttpRequestMeta<
    ResetPasswordRequest,
    void
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/account/resent-email-verification',
    method: 'POST',
});
