import { AppButton, FormBase, InputField, Spacer, useHttpCommand } from '@esg/ui';
import React, { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { accountApi, connectToken, ConnectTokenResponse } from '@esg/business-account';
import { useTranslation } from 'react-i18next';
import { convertOpenIdResponse, useLoginState } from '@esg/auth';
import { useLoginStore } from '@esg/ui/hooks/useExistingLogin';
import { AuthHeader } from '../@shared/components';

import { authRules } from '../@shared';
import { env, paths } from '@/configs';
import { translateCodes } from '@/locales';

interface LoginFormValues {
    readonly email: string;
    readonly password: string;
    readonly rememberMe: boolean;
}

export const InviteUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);

    const [, setLoginState] = useLoginState();
    const {setData: saveLogin } = useLoginStore();

    const onLoginSuccess = React.useCallback((response: ConnectTokenResponse) => {
        const loginStateValue = convertOpenIdResponse(response);
        setLoginState(loginStateValue);
        saveLogin(loginStateValue);
        navigate(paths.authorize);
    }, [navigate, saveLogin, setLoginState]);

    const { mutateAsync, isPending } = useHttpCommand(accountApi.verifyEmail);

    const loginCommand = useHttpCommand(connectToken, { 
        onSuccess: onLoginSuccess,
        showNotificationError: false
    });

    const onLogin = React.useCallback(async (data: LoginFormValues) => {
        const scope = 'openid';
        loginCommand.mutate({
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            body: {
                grant_type: 'password',
                username: data.email,
                password: data.password,
                client_id: env.IDENTITY_CLIENT_ID,
                scope: scope,
            },
        });
    },
    [loginCommand]);

    const onSubmit = React.useCallback(
        async (data: { password: string }) => {
            await mutateAsync(
                {
                    body: {
                        createConfirmEmailToken: params.secret.replace(/ /g, '+'),
                        userId: params.userId,
                        password: data.password,
                    },
                },
                {
                    onSuccess() {
                        onLogin({ 
                            email: params.email.replace(/ /g, '+'), 
                            password: data.password, 
                            rememberMe: false 
                        });
                    },
                }
            );
        },
        [mutateAsync, onLogin, params.email, params.secret, params.userId]
    );

    useEffect(() => {
        sessionStorage.setItem('redirect_uri', env.MANAGEMENT_LOGIN_CALLBACK_PAGE);
        sessionStorage.setItem('client_id', env.MANAGEMENT_WEB_CLIENT_ID);
    }, []);

    return (
        <FormBase onSubmit={onSubmit}>
            <AuthHeader
                headTranslateCode={`${t(translateCodes.JOIN)} ${params.organizationName}`}
                descTranslateCode={translateCodes.JOIN_IZISALON_DESC}
                userMail={params.email.replace(/ /g, '+')}
            />
            <Spacer bottom={24} top={48}>
                <InputField
                    name='password'
                    type='password'
                    label={translateCodes.PASSWORD}
                    placeholder={translateCodes.PASSWORD_PLACEHOLDER}
                    rules={authRules.password}
                />
            </Spacer>
            <AppButton
                loading={isPending}
                htmlType='submit'
                type='primary'
                width='100%'
                translateCode={translateCodes.JOIN_IZISALON}
            />
        </FormBase>
    );
};
