import { Tour, TourProps } from 'antd/lib';
import React, { useState, useMemo, useCallback } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './IntroduceTour.scss';
import { Block, FlexBox, Spacer, Text } from '@esg/ui';
import { Image } from 'antd';
import { translateCodes } from '@/locales';

interface IntroduceItemProps {
    readonly image: string;
    readonly title: string;
    readonly description: string;
}

export default function IntroduceTour() {
    const urlImageBase = '/assets/images/introduce-';
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(true);
    const [currentStep, setCurrentStep] = useState(0); // Track current step

    const renderStepIndicator = useCallback((current: number, total: number) => {
        const indicators = [];
        for (let i = 0; i < total; i++) {
            indicators.push(
                <Block
                    key={i}
                    onClick={() => setCurrentStep(i)}
                    className={`introduce-tour__indicator ${current === i ? 'active' : ''}`}
                />
            );
        }
        return indicators;
    }, []);

    const prevButtonProps = useMemo(()=> ({
        children: 'Skip',
        onClick: () => setOpen(false),
    }),[]);

    const steps: TourProps['steps'] = useMemo(()=> [
        {
            title: '',
            description: <IntroduceTour.Item 
                image={`${urlImageBase}1.png`}
                title={t(translateCodes.INTRODUCE_TITLE_1)} 
                description={t(translateCodes.INTRODUCE_DESC_1)}
            />,
            prevButtonProps: prevButtonProps,

        },
        {
            title: '',
            description: <IntroduceTour.Item
                image={`${urlImageBase}2.png`}
                title={t(translateCodes.INTRODUCE_TITLE_2)}
                description={t(translateCodes.INTRODUCE_DESC_2)}
            />,
            prevButtonProps: prevButtonProps,
        },
        {
            title: '',
            description: <IntroduceTour.Item
                image={`${urlImageBase}3.png`}
                title={t(translateCodes.INTRODUCE_TITLE_3)}
                description={t(translateCodes.INTRODUCE_DESC_3)}
            />,
            prevButtonProps: prevButtonProps,
        },
        {
            title: '',
            description: <IntroduceTour.Item
                image={`${urlImageBase}4.png`}
                title={t(translateCodes.INTRODUCE_TITLE_4)}
                description={t(translateCodes.INTRODUCE_DESC_4)}
            />,
            prevButtonProps: prevButtonProps
        },
        {
            title: '',
            description: <IntroduceTour.Item
                image={`${urlImageBase}5.png`}
                title={t(translateCodes.INTRODUCE_TITLE_5)}
                description={t(translateCodes.INTRODUCE_DESC_5)}
            />,
            nextButtonProps:{children: t(translateCodes.GET_STARTED)},
            prevButtonProps: prevButtonProps,
        }
    ],[t, prevButtonProps]);

    return (
        <Tour
            prefixCls='introduce-tour'
            open={open}
            onClose={() => setOpen(false)}
            steps={steps}
            closeIcon={<CloseCircleFilled width={30} height={30}/>}
            indicatorsRender={renderStepIndicator}
            onChange={(current) => setCurrentStep(current)}
            current={currentStep}
        />
    );
}

IntroduceTour.Item = function IntroduceTourItem({ image, title, description }: IntroduceItemProps) {
    return (
        <Spacer top={16}>
            <Image height={420} src={image} alt={title} preview={false} />
            <Spacer top_bottom={32} height={100}>            
                <FlexBox direction='column' gap={12} justifyContent='center' alignItems='center'>
                    <Text fontSize={24} fontWeight={600}>
                        {title}
                    </Text>
                    <Text fontSize={16} fontWeight={400} textAlign='center'>
                        {description}
                    </Text>
                </FlexBox>
            </Spacer>
        </Spacer>
    );
};