import { translateCodes } from '@/locales';

export const setupRules = {
    business: {
        required: translateCodes.VAL_AU_06,
    },
    phone: {
        pattern: {
            value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
            message: translateCodes.VAL_AU_07,
        },
    },
    branchName: {
        required: translateCodes.VAL_AU_08,
    },
    branchAddress: {
        required: translateCodes.VAL_AU_09,
    },
    country: {
        required: translateCodes.COUNTRY_IS_REQUIRED,
    },
    state: {
        required: translateCodes.VAL_AU_16,
    },
    city: {
        required: translateCodes.VAL_AU_17,
    },
    postcode: {
        required: translateCodes.VAL_AU_18,
    },
};
