import { FlexBox, InputField, SelectField, useLocationOptions } from '@esg/ui';
import React from 'react';

import { setupRules } from '../@shared';

import IntroduceTour from '../introduce-tour/IntroduceTour';
import { translateCodes } from '@/locales';

export const Info = () => {
    const {phoneCodeOptions} = useLocationOptions('', '');

    return (
        <>
            <IntroduceTour />
            <FlexBox justifyContent='space-between' gap={16}>
                <InputField
                    name='name'
                    label={translateCodes.BUSINESS_NAME}
                    placeholder={translateCodes.BUSINESS_NAME_PLACEHOLDER}
                    rules={setupRules.business}
                />
                <InputField
                    addonBefore={
                        <SelectField
                            showSearch
                            allowClear
                            style={{ width: 150 }}
                            name='countryCode'
                            options={phoneCodeOptions}
                        />
                    }
                    name='phoneNumber'
                    label={translateCodes.PHONE_NUMBER}
                    placeholder={translateCodes.PHONE_PLACEHOLDER}
                    rules={setupRules.phone}
                />
            </FlexBox>
        </>
    );
};
