import { accountApi } from '@esg/business-account';
import {
    AppButton,
    FormBase,
    InputField,
    Spacer
    , useHttpCommand ,
    FlexBox} from '@esg/ui';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authPaths, authRules } from '../../@shared';

import { translateCodes } from '@/locales';

export const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const params = Object.fromEntries([...searchParams]);

    const values = {
        userId: params.userId,
        secret: params.secret.replace(/ /g, '+'),
    };
    const { mutateAsync, isPending } = useHttpCommand(accountApi.resetPassword);

    const onSubmit = React.useCallback(
        async (data: { password: string }) => {
            
            await mutateAsync(
                {
                    body: {
                        userId: values.userId,
                        token: values.secret,
                        newPassword: data.password,
                    },
                },
                {
                    onSuccess() {
                        navigate(authPaths.resetPasswordSuccess);
                    },
                }
            );
            
        },
        [mutateAsync, navigate, values.secret, values.userId]
    );

    return (
        <FormBase onSubmit={onSubmit}>
            {(form)=> {
                form.register('confirm_password', {
                    validate: (val: string) => {
                        if (form.watch('password') !== val) {
                            return translateCodes.PASSWORD_NOT_MATCH;
                        }
                    },
                });

                return (
                    <>
                        <FlexBox alignItems='end'>
                            <InputField
                                name='password'
                                label={translateCodes.PASSWORD}
                                type='password'
                                placeholder={translateCodes.PASSWORD_PLACEHOLDER_SHORT}
                                rules={authRules.password}
                                tooltipContent={translateCodes.VALIDATE_PASSWORD}
                            />
                        </FlexBox>
                        <Spacer top={20} bottom='md'>
                            <FlexBox alignItems='end'>
                                <InputField
                                    name='confirm_password'
                                    label={translateCodes.CONFIRM_PASSWORD}
                                    type='password'
                                    placeholder={translateCodes.PASSWORD_PLACEHOLDER_SHORT}
                                    rules={authRules.password}
                                    tooltipContent={translateCodes.VALIDATE_CONFIRM_PASSWORD}
                                />
                            </FlexBox>
                        </Spacer>
                        <AppButton
                            loading={isPending}
                            htmlType='submit'
                            type='primary'
                            width='100%'
                            translateCode={translateCodes.RESET_PASSWORD}
                        />
                    </>
                );
            }}
        </FormBase>
    );
};
