import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { EmployeeInitCreate } from '@esg/business-account';
import {
    AppAvatar,
    AppButton,
    Block,
    FlexBox,
    globalModalState,
    Spacer,
    Text,
} from '@esg/ui';
import { Col, Row, Tooltip } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { EmployeeDeleteConfirm } from './EmployeeDeleteConfirm';
import { translateCodes } from '@/locales';

interface EmployeeItemProps {
  defaultData: EmployeeInitCreate[];
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
  showButton?: boolean;
}
export const EmployeeItem = (props: EmployeeItemProps) => {
    const { defaultData = [], onEdit, onDelete, showButton } = props;
    const { t } = useTranslation();
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const onDeleteService = useCallback(
        (id?: number) => {
            const deletedEmployee = defaultData?.find((o) => o.id == id);
            setGlobalModal({
                ...globalModal,
                isOpen: true,
                showModalDelete: true,
                footer: undefined,
                content: <EmployeeDeleteConfirm item={deletedEmployee!} />,
                onOk: () => {
                    onDelete(deletedEmployee!.id);
                    resetGlobalModal();
                },
            });
        },
        [defaultData, globalModal, onDelete, resetGlobalModal, setGlobalModal]
    );

    if (defaultData && defaultData?.length > 0 && defaultData != undefined) {
        return defaultData.map((o, index) => (
            <Row key={index} className='employee-item'>
                <Col span={23}>
                    <FlexBox alignItems='center' gap={14}>
                        {showButton && (
                            <Tooltip placement='topLeft' title={t(translateCodes.DELETE_EMPLOYEE_TITLE)}>
                                <AppButton
                                    icon={<CloseOutlined />}
                                    onClick={() => onDeleteService?.(o.id)}
                                />
                            </Tooltip>
                        )}
                        <AppAvatar
                            data={{ ...o, fullName: `${o.firstName} ${o.lastName}` }}
                            size='lg'
                        />
                        {`${o.firstName} ${o.lastName}`}
                    </FlexBox>
                </Col>
                <Col span={1}>
                    {showButton && (
                        <Tooltip placement='topLeft' title={t(translateCodes.UPDATE_EMPLOYEE_TITLE)}>
                            <AppButton
                                icon={<RightOutlined />}
                                onClick={() => onEdit?.(o.id)}
                            />
                        </Tooltip>
                    )}
                </Col>
            </Row>
        ));
    }
    return (
        <Block backgroundColor='#F9FAFB' borderRadius={12}>
            <Spacer type='padding' top_bottom={16} left_right={16}>
                <FlexBox alignItems='center' direction='column' gap={16}>
                    <img
                        src='/assets/auth/Empty_item.svg'
                        title='empty-service'
                        width={120}
                    />
                    <Text fontSize={14} color='#667085'>
                        {t(translateCodes.NO_EMPLOYEE)}
                    </Text>
                </FlexBox>
            </Spacer>
        </Block>
    );
};
