/* eslint-disable react-hooks/exhaustive-deps */
import './EmployeeSetup.scss';
import React, { useCallback } from 'react';
import { AppButton, FlexBox, globalModalState, SwitchField } from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { EmployeeInitCreate } from '@esg/business-account';
import { PlusCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { viewRequests } from '@esg/framework';
import { sampleEmployeeData } from '../@shared/dataSample';
import { EmployeeItem } from './children/EmployeeItem';
import { EmployeeForm } from './children/EmployeeForm';
import { translateCodes } from '@/locales';

export const EmployeeSetup = () => {
    const { t } = useTranslation();
    const { setValue, watch} = useFormContext();
    const [ globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const employees: EmployeeInitCreate[] = watch('employees') ?? [];  
    const useSampleEmployee = watch('useSampleEmployee');

    const onCancel = useCallback(()=>{
        resetGlobalModal?.();
    },[resetGlobalModal]);

    const onSubmitEmployee = useCallback((value: EmployeeInitCreate)=>{
        const isUpdate = value.id != null; 

        if (isUpdate) {
            const updatedCategoryList = employees.map((o) => 
                o.id === value.id ? value : o
            );
            setValue('employees', updatedCategoryList);
            resetGlobalModal?.();
            viewRequests.showNotification.send({
                type: 'success',
                title: t(translateCodes.UPDATE_EMPLOYEE_SUCCESS),
                message: '',
            });
            return;
        }
        
        const lastEmployee = employees.findLast(o=>o);
        const newId = lastEmployee ? lastEmployee.id + 1 : 0;

        const newCategory: EmployeeInitCreate = {
            ...value,
            id: newId,
            type: 'added',
        };
        const updatedEmployee = [...employees, newCategory];
        setValue('employees', updatedEmployee);
        resetGlobalModal();
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.CREATE_EMPLOYEE_SUCCESS),
            message: '',
        });
    },[employees, resetGlobalModal, setValue]); 

    const handlerEmployee = useCallback((id?: number) => {
        const isUpdate = id != undefined ? true : false;
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            footer: null,
            titleTransCode: isUpdate ? translateCodes.UPDATE_EMPLOYEE_TITLE : translateCodes.CREATE_EMPLOYEE_TITLE,
            content: <EmployeeForm
                defaultData={employees.find(o=>o.id == id)}
                onCancel={onCancel}
                onSubmit={onSubmitEmployee}
                watch={watch}
                setValue={setValue}
                isUpdate={isUpdate}
            />
        });
    }, [employees, globalModal, onCancel, onSubmitEmployee, setGlobalModal, setValue, watch]);

    const onDeleteEmployee = useCallback((id: number) => {
        const updateServiceList = employees.filter(o=>o.id != id);
        setValue('employees', updateServiceList);
        resetGlobalModal?.();
        viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.DELETE_EMPLOYEE_SUCCESS),
            message: '',
        });
    }, [employees, resetGlobalModal, setValue]);

    const isDisableUseSample = employees.some(o=> o.type === 'added');

    return (
        <FlexBox direction='column' gap={32}>
            <SwitchField
                name='useSampleEmployee'
                directionLabel='row-reverse'
                label={translateCodes.USE_SAMPLE}
                onChange={(value) => {
                    setValue('employees', value ? sampleEmployeeData : []);
                }}
                disabled={isDisableUseSample}
                className='use-sample-switch'
            />
            <FlexBox direction='column' className='main-content-employee' gap={16} >
                <EmployeeItem
                    defaultData={employees}
                    onDelete={onDeleteEmployee}
                    onEdit={handlerEmployee}
                    showButton={!useSampleEmployee}
                />
            </FlexBox>
            <FlexBox>
                {!useSampleEmployee && <AppButton
                    translateCode={t(translateCodes.CREATE_EMPLOYEE)}
                    icon={<PlusCircleFilled />}
                    type='link'
                    onClick={()=>handlerEmployee()}
                    padding={0}
                />}
            </FlexBox>
        </FlexBox>
    );
};

