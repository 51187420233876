import './index.css';

import { ThemeProvider, AppConfig, ModalGlobal } from '@esg/ui';
import { crmLocale } from '@esg/business-crm';
import { viewRequests } from '@esg/framework';
import { notification } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { defaultLocale } from './locales';
import { Router } from './pages';
import { Initializer } from './app';

const getRootElement = () => document.getElementById('app')!;
let reactRoot: ReactDOM.Root | null = null;

const renderApp = () => {
    const root = getRootElement();
    const app = (
        <AppConfig i18nResources={[defaultLocale, crmLocale]}>
            <ThemeProvider>
                <Initializer>
                    <ModalGlobal />
                    <Router />
                </Initializer>
            </ThemeProvider>
        </AppConfig>
    );

    if (reactRoot == null) {
        reactRoot = ReactDOM.createRoot(root);
        reactRoot.render(app);
    } else {
        reactRoot.render(app);
    }
};

export const startApp = () => {
    viewRequests.showNotification.setHandler(async (payload) => {
        if (!payload) {
            throw new Error('Payload is required');
        }

        notification[payload.type]({
            message: <span>{payload.title}</span>,
            description: payload.message,
            placement: 'bottomLeft',
            duration: payload.duration ?? 3,
            icon: payload.type == 'success' && <img width='48' src='/assets/images/success-icon.png' />,
            style: {padding: 10, minHeight: 68, marginRight: 55},
        });
    });

    renderApp();
};
