import { accountApi } from '@esg/business-account';
import { viewRequests } from '@esg/framework';
import { useHttpCommand } from '@esg/ui';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { EmailCheck } from '../@shared';

import { translateCodes } from '@/locales';

export const EmailCheckVerify = () => {
    const { t } = useTranslation();
    const { state: { email, userId, token } } = useLocation();

    const { mutateAsync, isPending } = useHttpCommand(
        accountApi.resentEmailVerify
    );

    const handleResentEmailVerify = useCallback(async () => {
        await mutateAsync(
            {
                body: {
                    email: email,
                },
            },
            {
                onSuccess() {
                    viewRequests.showNotification.send({
                        type: 'success',
                        title: t(translateCodes.RESEND_EMAIL_VERIFY_SUCCESS),
                        message: t(translateCodes.CHECK_YOUR_EMAIL_NOW),
                    });
                },
            }
        );
    }, [email, mutateAsync, t]);

    return (
        <EmailCheck
            handler={handleResentEmailVerify}
            isLoading={isPending}
            description={translateCodes.SENT_VERIFY_CONFIRM_CODE_TO}
            userEmail={email}
            userId={userId}
            token={token}
        />
    );
};
