import { getUserProfile } from '@esg/business-account';
import { Block, useHttpQuery, useLoginPage } from '@esg/ui';
import { Result, Spin } from 'antd';
import React from 'react';

import { useLoginState } from '@esg/auth';
import { SelectOrganization } from './children';

import { env } from '@/configs';

export const Authorize = () => {
    const toLoginPage = useLoginPage();
    const { data: userProfile, isFetching } = useHttpQuery(getUserProfile);
    const [loginInfo] = useLoginState();

    const sessionInfo = React.useMemo(() => {
        const redirect_uri = sessionStorage.getItem('redirect_uri');
        const client_id = sessionStorage.getItem('client_id');

        let scope = 'openid';

        const refreshToken = loginInfo?.refreshToken;
        if (refreshToken) {
            scope += ' offline_access';
        }

        if (client_id === env.MANAGEMENT_WEB_CLIENT_ID) {
            scope += ` ${env.MANAGEMENT_SERVER_ID}`;
        }

        return {
            redirect_uri,
            client_id,
            scope,
        };
    }, [loginInfo?.refreshToken]);

    React.useEffect(() => {

        if (loginInfo) {
            return;
        }

        if (sessionInfo) {
            toLoginPage({
                redirectUri: sessionInfo.redirect_uri!,
                clientId: sessionInfo.client_id!,
            });
        }
        else {
            location.href = '/';
        }
    }, [loginInfo, sessionInfo, toLoginPage]);

    if (!userProfile || isFetching) {
        return (
            <Block textAlign='center'>
                <Spin tip='Loading your profile' />
            </Block>
        );
    }

    if (!sessionInfo.client_id || !sessionInfo.redirect_uri || !sessionInfo.scope) {
        return (
            <Result
                status='error'
                title='Authorize Failed'
                subTitle='Missing client_id, redirect_uri or scope'
            />
        );
    }

    if (sessionInfo.client_id === env.MANAGEMENT_WEB_CLIENT_ID) {
        return (
            <SelectOrganization
                userOrganizations={userProfile.userOrganizations}
                redirectUri={sessionInfo.redirect_uri}
                clientId={sessionInfo.client_id}
                scope={sessionInfo.scope}
            />
        );
    }

    return <>CLIENT_NOT_SUPPORTED</>;
};