export enum BusinessCategoryEnum {
    HairSalon = 0,
    NailsSalon = 1,
    BarberShop = 2,
    BeautySalon = 3,
    Aesthetics = 4,
    Spa = 5,
    Massage = 6,
    WaxingSalon = 7,
    TanningStudio = 8,
    EyebrowsLashes = 9,
    TattooPiercing = 10,
    TherapyCenter = 11,
    WeightLoss = 12,
    PersonalTrainer = 13,
    GymFitness = 14,
    OtherBusiness = 15,
}