import React from 'react';
import { IconProps } from '../../Types';

export const IconAesthetics = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_35600)'>
                <path
                    d='M26.309 18.1235C30.2004 14.232 30.739 8.46135 27.5119 5.23426C24.2848 2.00717 18.5141 2.54571 14.6227 6.43712C10.7313 10.3285 10.1927 16.0992 13.4198 19.3263C16.6469 22.5534 22.4176 22.0149 26.309 18.1235Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.4062 4.2204C17.4589 -0.832398 25.2192 -1.49251 29.7293 3.01752L29.2874 3.45947L29.7293 3.01753C34.2394 7.52763 33.5792 15.2879 28.5266 20.3407L28.0846 19.8988L28.5266 20.3407C24.2413 24.626 18.035 25.7457 13.4844 23.2527L11.5259 25.2112L11.5259 25.2112C10.5005 26.2365 9.76545 27.517 9.32728 28.9218L9.32727 28.9218C9.12252 29.5781 8.76024 30.1956 8.2417 30.7141C6.5125 32.4433 3.69958 32.4285 1.98909 30.6697C0.357594 28.9922 0.338293 26.2925 1.94755 24.5929C2.48732 24.0228 3.14307 23.6294 3.84225 23.4144L3.84228 23.4144C5.22746 22.9885 6.49014 22.2666 7.49981 21.257L9.49421 19.2626C7.00125 14.712 8.12091 8.50568 12.4062 4.2204ZM28.8454 3.90141C24.9407 -0.00330353 17.9807 0.413583 13.2901 5.10428L12.8481 4.66234L13.2901 5.10428C9.20061 9.19375 8.35426 15.0349 10.8086 19.0382C10.9597 19.2846 10.9221 19.6024 10.7178 19.8068L8.3837 22.1408C7.21512 23.3094 5.76893 24.1298 4.20961 24.6092C3.71118 24.7624 3.2431 25.0426 2.85526 25.4523L2.85522 25.4524C1.70722 26.6648 1.72067 28.6009 2.88518 29.7982L2.43713 30.234L2.88518 29.7982C4.10893 31.0565 6.12088 31.0671 7.35781 29.8302C7.7299 29.4581 7.98799 29.0175 8.13399 28.5495C8.62583 26.9728 9.45885 25.5104 10.642 24.3273C10.642 24.3273 10.642 24.3273 10.642 24.3273L12.9402 22.0292C13.1446 21.8248 13.4624 21.7872 13.7088 21.9383C17.7121 24.3927 23.5532 23.5463 27.6427 19.4568C32.3333 14.7661 32.7502 7.80618 28.8454 3.90141Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.09107 1.94765C4.59642 1.02385 5.92329 1.02388 6.42861 1.94774L6.91068 2.8292C6.91816 2.84288 6.92946 2.85424 6.94334 2.86186L7.82471 3.34386C8.74859 3.84919 8.74863 5.17611 7.82475 5.68143L6.94329 6.16351C6.92944 6.17108 6.91813 6.18244 6.91069 6.19605L6.42856 7.07762C5.92323 8.00149 4.59632 8.00152 4.091 7.07764L3.60898 6.19617C3.6015 6.1825 3.59019 6.17112 3.57631 6.16351M3.57631 6.16351L2.69487 5.68145C1.771 5.17612 1.77097 3.84921 2.69485 3.34388L3.57631 2.86181C3.58999 2.85433 3.60136 2.84302 3.60898 2.82914L4.09107 1.94765'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M26.8391 24.6937C27.3445 23.7699 28.6713 23.77 29.1767 24.6938L29.6587 25.5753C29.6662 25.589 29.6775 25.6003 29.6914 25.608L30.5728 26.09C31.4966 26.5953 31.4967 27.9222 30.5728 28.4275L29.6913 28.9096C29.6775 28.9172 29.6662 28.9285 29.6587 28.9421L29.1766 29.8237C28.6713 30.7476 27.3444 30.7476 26.839 29.8237L26.357 28.9423C26.3495 28.9286 26.3382 28.9172 26.3244 28.9096M26.3244 28.9096L25.4429 28.4275C24.519 27.9222 24.519 26.5953 25.4429 26.09L26.3244 25.6079C26.338 25.6004 26.3494 25.5891 26.357 25.5752L26.8391 24.6937'
                    fill='#9BD7FD'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_35600'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.75)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
