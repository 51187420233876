import React from 'react';
import { IconProps } from '../../Types';

export const IconBarberShop = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_43259)'>
                <path
                    d='M8.90569 22.903H5.14038V29.1785H8.90569V22.903Z'
                    fill='#9BD7FD'
                />
                <path
                    d='M8.90569 6.58673H5.14038V19.7653H8.90569V6.58673Z'
                    fill='#9BD7FD'
                />
                <path
                    d='M28.3598 6.58673H24.5945V19.7653H28.3598V6.58673Z'
                    fill='#9BD7FD'
                />
                <path
                    d='M28.3598 22.903H24.5945V29.1785H28.3598V22.903Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.9108 20.0882C18.6261 20.2883 18.5576 20.6813 18.7577 20.966C18.9578 21.2508 19.3509 21.3193 19.6356 21.1192C19.9203 20.919 19.9888 20.526 19.7887 20.2413C19.5886 19.9566 19.1955 19.888 18.9108 20.0882ZM17.7351 21.6849C17.1379 20.8354 17.3425 19.6627 18.1919 19.0655C19.0414 18.4684 20.2142 18.6729 20.8113 19.5224C21.4085 20.3719 21.2039 21.5446 20.3545 22.1418C19.505 22.739 18.3322 22.5344 17.7351 21.6849Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.1344 13.4793C14.4143 13.2774 14.805 13.3406 15.0069 13.6205L19.0455 19.2188C19.2475 19.4988 19.1843 19.8894 18.9043 20.0914C18.6244 20.2933 18.2337 20.2301 18.0318 19.9502L13.9932 14.3518C13.7912 14.0719 13.8545 13.6813 14.1344 13.4793Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7118 20.239C13.5117 20.5237 13.5802 20.9167 13.8649 21.1169C14.1496 21.317 14.5427 21.2484 14.7428 20.9637C14.9429 20.679 14.8744 20.286 14.5897 20.0859C14.305 19.8857 13.9119 19.9543 13.7118 20.239ZM13.146 22.1395C12.2966 21.5423 12.092 20.3696 12.6892 19.5201C13.2863 18.6706 14.4591 18.4661 15.3086 19.0632C16.158 19.6604 16.3626 20.8331 15.7654 21.6826C15.1683 22.5321 13.9955 22.7367 13.146 22.1395Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.3655 13.4793C19.6454 13.6813 19.7087 14.0719 19.5067 14.3518L15.4681 19.9502C15.2662 20.2301 14.8755 20.2933 14.5956 20.0914C14.3156 19.8894 14.2524 19.4988 14.4544 19.2188L18.493 13.6205C18.6949 13.3406 19.0856 13.2774 19.3655 13.4793Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.5625 7.21173C2.69955 7.21173 2 7.91128 2 8.77423V26.991C2 27.854 2.69955 28.5535 3.5625 28.5535H29.9375C30.8004 28.5535 31.5 27.854 31.5 26.991V8.77423C31.5 7.91128 30.8004 7.21173 29.9375 7.21173H3.5625ZM0.75 8.77423C0.75 7.22093 2.0092 5.96173 3.5625 5.96173H29.9375C31.4908 5.96173 32.75 7.22093 32.75 8.77423V26.991C32.75 28.5443 31.4908 29.8035 29.9375 29.8035H3.5625C2.0092 29.8035 0.75 28.5443 0.75 26.991V8.77423Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.2908 3.44641C12.6004 3.44641 12.0408 4.00603 12.0408 4.69641V5.96172H21.4591V4.69641C21.4591 4.00603 20.8995 3.44641 20.2091 3.44641H13.2908ZM10.7908 4.69641C10.7908 3.31567 11.91 2.19641 13.2908 2.19641H20.2091C21.5899 2.19641 22.7091 3.31567 22.7091 4.69641V6.58672C22.7091 6.9319 22.4293 7.21172 22.0841 7.21172H11.4158C11.0706 7.21172 10.7908 6.9319 10.7908 6.58672V4.69641Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24.3185 20.3902C24.1229 20.3902 23.9644 20.5488 23.9644 20.7444V21.9238C23.9644 22.1194 24.1229 22.278 24.3185 22.278H28.6357C28.8314 22.278 28.9899 22.1195 28.9899 21.9238V20.7444C28.9899 20.5488 28.8313 20.3902 28.6357 20.3902H24.3185ZM22.7144 20.7444C22.7144 19.8585 23.4325 19.1402 24.3185 19.1402H28.6357C29.5216 19.1402 30.2399 19.8584 30.2399 20.7444V21.9238C30.2399 22.8098 29.5217 23.528 28.6357 23.528H24.3185C23.4326 23.528 22.7144 22.8098 22.7144 21.9238V20.7444Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.86444 20.3902C4.66883 20.3902 4.51025 20.5488 4.51025 20.7444V21.9238C4.51025 22.1194 4.66884 22.278 4.86444 22.278H9.18163C9.37714 22.278 9.53576 22.1195 9.53582 21.9238C9.53582 21.9238 9.53582 21.9238 9.53582 21.9238V20.7444C9.53582 20.5488 9.37723 20.3902 9.18163 20.3902H4.86444ZM3.26025 20.7444C3.26025 19.8585 3.97842 19.1402 4.86444 19.1402H9.18163C10.0675 19.1402 10.7858 19.8584 10.7858 20.7444V21.9239C10.7857 22.8098 10.0676 23.528 9.18163 23.528H4.86444C3.97854 23.528 3.26025 22.8098 3.26025 21.9238V20.7444Z'
                    fill='#0C6FF3'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_43259'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.75)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
