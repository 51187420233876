import { ArrowLeftOutlined } from '@ant-design/icons';
import { accountApi } from '@esg/business-account';
import { AppButton, Block , urlUtil, useHttpCommand } from '@esg/ui';
import { Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { translateCodes } from '@/locales';
import { env } from '@/configs';

export const VerifyEmailForm = () => {
    const redirectParams = {
        client_id: env.MANAGEMENT_CLIENT_ID,
        redirect_uri: env.MANAGEMENT_LOGIN_CALLBACK_PAGE
    };
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = React.useState<boolean | null>(false);
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);

    const values = {
        userId: params.userId,
        secret: params.secret.replace(/ /g, '+'),
    };

    const { mutate } = useHttpCommand(accountApi.verifyEmail);

    useEffect(() => {
        mutate(
            {
                body: {
                    createConfirmEmailToken: values.secret,
                    userId: values.userId,
                },
            },
            {
                onSuccess() {
                    setIsSuccess(true);
                },
            }
        );
    }, [mutate, navigate, values.secret, values.userId]);

    if (isSuccess) {
        return (
            <Result
                status='success'
                title='Successfully'
                subTitle='Please login to start using the platform'
                extra={
                    <AppButton
                        href={urlUtil.toRelativeUrl(env.IDENTITY_LOGIN_PAGE, redirectParams)}
                        type='text'
                        icon={<ArrowLeftOutlined />}
                        translateCode={translateCodes.BACK_TO_LOG_IN}
                    />
                }
            />
        );
    }

    return (
        <Spin size='large' fullscreen>
            <Block />
        </Spin>
    );
};
