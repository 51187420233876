import { urlUtil } from '@esg/ui';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export const ExternalCallback = () => {
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);

    if (params.action === 'register' && params.error) {
        return (
            <Navigate
                to={urlUtil.toRelativeUrl('/auth/register', params)}
            />
        );
    }

    return (
        <Navigate
            to={urlUtil.toRelativeUrl('/auth/login', params)}
        />
    );
};