import React from 'react';
import { IconProps } from '../../Types';

export const IconGymFitness = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_43520)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.4043 18.0253C11.4043 18.3704 11.6841 18.6503 12.0293 18.6503H21.4705C21.8157 18.6503 22.0955 18.3704 22.0955 18.0253V14.6742C22.0955 14.329 21.8157 14.0492 21.4705 14.0492H12.0293C11.6841 14.0492 11.4043 14.329 11.4043 14.6742V18.0253ZM12.6543 17.4003V15.2992H20.8455V17.4003H12.6543Z'
                    fill='#0C6FF3'
                />
                <mask
                    id='mask0_4955_43520'
                    style={{ maskType: 'luminance' }}
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='33'
                    height='32'
                >
                    <path
                        d='M0.75 1.90735e-06H32.75V32H0.75V1.90735e-06Z'
                        fill='white'
                    />
                </mask>
                <g mask='url(#mask0_4955_43520)'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M28.9919 18.0253C28.9919 18.3704 29.2718 18.6503 29.6169 18.6503H32.1251C32.4702 18.6503 32.7501 18.3704 32.7501 18.0253V14.6742C32.7501 14.329 32.4702 14.0492 32.1251 14.0492H29.6169C29.2718 14.0492 28.9919 14.329 28.9919 14.6742V18.0253ZM30.2419 17.4003V15.2992H31.5001V17.4003H30.2419Z'
                        fill='#0C6FF3'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.75 18.0253C0.75 18.3704 1.02982 18.6503 1.375 18.6503H3.88312C4.2283 18.6503 4.50813 18.3704 4.50813 18.0253V14.6742C4.50813 14.329 4.2283 14.0492 3.88312 14.0492H1.375C1.02982 14.0492 0.75 14.329 0.75 14.6742V18.0253ZM2 17.4003V15.2992H3.25813V17.4003H2Z'
                        fill='#0C6FF3'
                    />
                    <path
                        d='M26.6194 11.305H29.601C29.9549 11.305 30.2418 11.5919 30.2418 11.9459V20.0541C30.2418 20.4081 29.9549 20.695 29.601 20.695H26.6194'
                        fill='#9BD7FD'
                    />
                    <path
                        d='M6.88049 20.695H3.89887C3.54493 20.695 3.25806 20.4081 3.25806 20.0541V11.9459C3.25806 11.5919 3.54493 11.305 3.89887 11.305H6.88049'
                        fill='#9BD7FD'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M20.8457 21.7766C20.8457 22.6681 21.5684 23.3909 22.46 23.3909H25.4733C26.3649 23.3909 27.0877 22.6682 27.0877 21.7766V10.2234C27.0877 9.33179 26.3649 8.60905 25.4733 8.60905H22.46C21.5684 8.60905 20.8457 9.33184 20.8457 10.2234V21.7766ZM22.46 22.1409C22.2588 22.1409 22.0957 21.9778 22.0957 21.7766V10.2234C22.0957 10.0221 22.2588 9.85905 22.46 9.85905H25.4733C25.6746 9.85905 25.8377 10.0222 25.8377 10.2234V21.7766C25.8377 21.9778 25.6746 22.1409 25.4733 22.1409H22.46Z'
                        fill='#0C6FF3'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M6.41235 21.7766C6.41235 22.6682 7.13514 23.3909 8.02673 23.3909H11.04C11.9317 23.3909 12.6544 22.6682 12.6544 21.7766V10.2234C12.6544 9.33185 11.9317 8.60907 11.04 8.60907H8.02673C7.13514 8.60907 6.41235 9.3318 6.41235 10.2234V21.7766ZM8.02673 22.1409C7.82544 22.1409 7.66235 21.9778 7.66235 21.7766V10.2234C7.66235 10.0222 7.82544 9.85907 8.02673 9.85907H11.04C11.2413 9.85907 11.4044 10.0222 11.4044 10.2234V21.7766C11.4044 21.9779 11.2413 22.1409 11.04 22.1409H8.02673Z'
                        fill='#0C6FF3'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_4955_43520'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.75)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
