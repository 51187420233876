import { httpUtil } from '@esg/framework';

import { env } from '../../config';

interface RegisterRequest {
    readonly body: {
        readonly email: string;
        readonly password: string;
        readonly firstName: string;
        readonly lastName: string;
    };
}

interface RegisterResponse {
    readonly id: string;
    readonly token: string;
    readonly email: string;
}

export const register = httpUtil.createHttpRequestMeta<RegisterRequest, RegisterResponse>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/account/register',
    method: 'POST',
});
