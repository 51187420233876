import React from 'react';
import { IconProps } from '../../Types';

export const IconTherapyCentre = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.40803 19.2211C9.49161 19.556 9.28787 19.8953 8.95296 19.9788C7.0527 20.4531 5.47878 21.2121 4.39357 22.1258C3.30836 23.0394 2.75513 24.0653 2.75513 25.0995C2.75513 26.5024 3.78606 27.9039 5.74217 28.9906C7.67712 30.0656 10.3925 30.75 13.426 30.75C14.8983 30.75 16.2987 30.5886 17.573 30.2976C17.9096 30.2207 18.2447 30.4312 18.3215 30.7677C18.3984 31.1042 18.1878 31.4393 17.8513 31.5162C16.4824 31.8288 14.9885 32 13.426 32C10.221 32 7.28851 31.2796 5.13513 30.0833C3.00291 28.8988 1.50513 27.1625 1.50513 25.0995C1.50513 23.5833 2.32121 22.2365 3.58853 21.1695C4.85585 20.1026 6.61768 19.2733 8.65029 18.766C8.9852 18.6825 9.32445 18.8862 9.40803 19.2211Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.4441 19.221C17.5277 18.8861 17.8669 18.6824 18.2018 18.766C20.6662 19.3813 22.7456 20.4738 24.0178 21.8986C24.0655 21.9494 24.099 22.0021 24.1217 22.0475C24.1576 22.1192 24.1724 22.1833 24.1793 22.2242C24.183 22.2462 24.185 22.2658 24.1862 22.2824C24.1869 22.2909 24.1873 22.2988 24.1875 22.3061C24.1876 22.3098 24.1877 22.3134 24.1878 22.3169C24.1878 22.3186 24.1878 22.3203 24.1879 22.322L24.1879 22.3245L24.1879 22.3263C24.1879 22.3266 24.1879 22.3269 23.5629 22.3276L24.1879 22.3269C24.1882 22.6721 23.9087 22.9522 23.5635 22.9526C23.3909 22.9527 23.2345 22.8829 23.1213 22.7699C23.1123 22.7609 23.1035 22.7516 23.0949 22.7419C22.0465 21.5614 20.2228 20.559 17.899 19.9788C17.5641 19.8952 17.3604 19.5559 17.4441 19.221Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.5959 21.386C22.7514 21.3203 22.9269 21.3203 23.0824 21.386C25.4683 22.3941 27.1446 24.7564 27.1446 27.5118C27.1446 29.1344 26.562 30.6223 25.5961 31.7762C25.4773 31.918 25.3018 32 25.1168 32H20.5614C20.3764 32 20.2009 31.918 20.0822 31.7762C19.1163 30.6222 18.5337 29.1345 18.5337 27.5118C18.5337 24.7565 20.21 22.3941 22.5959 21.386ZM22.8391 22.6474C21.0302 23.5201 19.7837 25.3711 19.7837 27.5118C19.7837 28.7274 20.1852 29.8477 20.8631 30.75H24.8151C25.4931 29.8477 25.8946 28.7274 25.8946 27.5118C25.8946 25.371 24.6481 23.5201 22.8391 22.6474Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.1321 22.1887C26.2876 22.123 26.4631 22.1229 26.6186 22.1886C28.8205 23.1188 30.3672 25.2982 30.3672 27.8412C30.3672 27.8541 30.3672 27.8701 30.3671 27.8867C30.3504 30.1712 28.4785 32 26.2032 32H24.292C23.9468 32 23.667 31.7202 23.667 31.375C23.667 31.0298 23.9468 30.75 24.292 30.75H26.2032C27.8012 30.75 29.1058 29.4657 29.1171 27.8771L29.1171 27.8757C29.1172 27.8657 29.1172 27.8551 29.1172 27.8412C29.1172 25.913 28.0002 24.2451 26.3755 23.4508C26.04 23.6149 25.7261 23.8161 25.4394 24.0494C25.1716 24.2672 24.7779 24.2267 24.5601 23.9589C24.3423 23.6911 24.3828 23.2974 24.6506 23.0796C25.0964 22.717 25.5946 22.4158 26.1321 22.1887Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.0599 22.1886C19.2154 22.1229 19.3909 22.123 19.5464 22.1887C20.0839 22.4158 20.5821 22.7171 21.0279 23.0797C21.2956 23.2975 21.3361 23.6911 21.1183 23.9589C20.9005 24.2267 20.5068 24.2672 20.2391 24.0493C19.9524 23.8161 19.6385 23.6149 19.3031 23.4508C17.6783 24.2451 16.5613 25.913 16.5613 27.8412C16.5613 27.8552 16.5613 27.8657 16.5614 27.8757L16.5614 27.8771C16.5728 29.4657 17.8773 30.75 19.4753 30.75H21.3865C21.7317 30.75 22.0115 31.0298 22.0115 31.375C22.0115 31.7202 21.7317 32 21.3865 32H19.4753C17.2 32 15.3281 30.1712 15.3114 27.8867C15.3113 27.8706 15.3113 27.8549 15.3113 27.8423V27.8412C15.3113 25.2982 16.858 23.1188 19.0599 22.1886Z'
                fill='#0C6FF3'
            />
            <path
                d='M18.2716 10.1602C17.0411 10.8593 15.3249 11.2934 13.4259 11.2934C11.527 11.2934 9.81072 10.8593 8.58022 10.1602C5.59154 11.1547 3.76416 12.9937 3.76416 15.0587C3.76416 16.6367 4.81829 18.0915 6.73241 19.1549C8.53054 20.1539 10.9077 20.7041 13.4259 20.7041C15.9442 20.7041 18.3212 20.1539 20.1194 19.1549C22.0335 18.0915 23.0877 16.6367 23.0877 15.0587C23.0877 12.9937 21.2603 11.1547 18.2716 10.1602Z'
                fill='#9BD7FD'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.93707 5.41194C7.79862 6.04441 7.27344 6.81835 7.27344 7.52807C7.27344 8.23779 7.79862 9.01173 8.93707 9.6442C10.0543 10.2649 11.6402 10.6684 13.426 10.6684C15.2118 10.6684 16.7977 10.2649 17.9149 9.6442C19.0534 9.01173 19.5786 8.23779 19.5786 7.52807C19.5786 6.81835 19.0534 6.04441 17.9149 5.41194C16.7977 4.79123 15.2118 4.38776 13.426 4.38776C11.6402 4.38776 10.0543 4.79123 8.93707 5.41194ZM8.33001 4.31924C9.66573 3.57718 11.4687 3.13776 13.426 3.13776C15.3833 3.13776 17.1863 3.57718 18.522 4.31924C19.8365 5.04955 20.8286 6.15826 20.8286 7.52807C20.8286 8.89788 19.8365 10.0066 18.522 10.7369C17.1863 11.479 15.3833 11.9184 13.426 11.9184C11.4687 11.9184 9.66573 11.479 8.33001 10.7369C7.01547 10.0066 6.02344 8.89788 6.02344 7.52807C6.02344 6.15826 7.01547 5.04955 8.33001 4.31924Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M30.37 1.25513C30.7152 1.25513 30.995 1.53495 30.995 1.88013V4.2885C30.995 5.74266 30.0645 7.0336 28.685 7.49348L28.685 7.49349C27.8159 7.78317 27.2297 8.5965 27.2297 9.51263V11.921C27.2297 12.2662 26.9499 12.546 26.6047 12.546C26.2596 12.546 25.9797 12.2662 25.9797 11.921V9.51263C25.9797 8.05851 26.9102 6.76746 28.2897 6.30763C29.1588 6.01788 29.745 5.20458 29.745 4.2885V1.88013C29.745 1.53495 30.0248 1.25513 30.37 1.25513Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M27.232 0C27.5772 0 27.857 0.279822 27.857 0.625V2.40581C27.857 3.85997 26.9265 5.15091 25.5471 5.61079L25.5471 5.61081C24.678 5.90048 24.0918 6.71381 24.0918 7.62994V9.41075C24.0918 9.75593 23.812 10.0358 23.4668 10.0358C23.1216 10.0358 22.8418 9.75593 22.8418 9.41075V7.62994C22.8418 6.17583 23.7722 4.8848 25.1518 4.42496M27.232 0C26.8869 0 26.607 0.279822 26.607 0.625L27.232 0ZM26.607 0.625V2.40581V0.625ZM26.607 2.40581C26.607 3.32189 26.0208 4.13519 25.1518 4.42494L26.607 2.40581Z'
                fill='#0C6FF3'
            />
        </svg>
    );
};
