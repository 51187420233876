
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { translateCodes } from '@/locales';

export const SetupConfirmModal = () => {
    return (
        <Spacer top='xs'>
            <Paragraph fontSize={18} fontWeight={700}>
                <Text translateCode={translateCodes.BRANCH_CREATE_SUCCESS_ALERT} />
            </Paragraph>
            <Spacer type='margin' top={8} />
            <Paragraph>
                <Text translateCode={translateCodes.BRANCH_CREATE_DESCRIPTION_ALERT} />
            </Paragraph>
        </Spacer>
    );
};
