import React from 'react';
import { IconProps } from '../../Types';

export const IconPersonalTrainer = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_42428)'>
                <path
                    d='M24.2656 27.7333C24.1525 26.8287 23.8054 25.9693 23.2583 25.24C22.5783 24.3333 22.2107 23.2304 22.2107 22.097V19.7961H18.2891V20.2667C18.2891 21.3929 17.3762 22.3059 16.2499 22.3059C15.1236 22.3059 14.2107 21.3929 14.2107 20.2667V19.7961H10.2891V22.097C10.2891 23.2304 9.9215 24.3333 9.24147 25.24C8.69452 25.9693 8.34729 26.8287 8.23422 27.7333C7.99265 29.6658 9.49941 31.3726 11.4468 31.3726H21.0529C23.0004 31.3726 24.5072 29.6657 24.2656 27.7333Z'
                    fill='#9BD7FD'
                />
                <path
                    d='M14.6814 11.2941V10.6667C14.6814 10.3201 14.4005 10.0392 14.054 10.0392C13.7074 10.0392 13.4265 10.3201 13.4265 10.6667V11.2941C13.4265 11.6407 13.7074 11.9216 14.054 11.9216C14.4005 11.9216 14.6814 11.6407 14.6814 11.2941Z'
                    fill='#0C6FF3'
                />
                <path
                    d='M18.4461 11.9216C18.7925 11.9216 19.0735 11.6407 19.0735 11.2941V10.6667C19.0735 10.3201 18.7925 10.0392 18.4461 10.0392C18.0996 10.0392 17.8186 10.3201 17.8186 10.6667V11.2941C17.8186 11.6407 18.0996 11.9216 18.4461 11.9216Z'
                    fill='#0C6FF3'
                />
                <path
                    d='M18.111 14.3735L18.2105 14.2939C18.4811 14.0774 18.525 13.6826 18.3084 13.412C18.0919 13.1415 17.6972 13.0975 17.4265 13.314L17.327 13.3936C16.6928 13.901 15.8069 13.9009 15.1726 13.3936L15.0732 13.314C14.8026 13.0975 14.4076 13.1415 14.1912 13.412C13.9748 13.6825 14.0186 14.0774 14.2892 14.2939L14.3886 14.3735C15.4846 15.2501 17.0152 15.2501 18.111 14.3735Z'
                    fill='#0C6FF3'
                />
                <path
                    d='M22.5558 19.1686H18.9166V17.9103C20.8682 16.9299 22.2107 14.9092 22.2107 12.5804V7.52941C22.2107 5.30478 20.7556 3.41459 18.7474 2.75796C18.893 1.28188 17.7317 0 16.25 0C14.769 0 13.6068 1.28088 13.7525 2.75796C11.7444 3.41459 10.2892 5.30478 10.2892 7.52941V12.5804C10.2892 14.9092 11.6317 16.9299 13.5833 17.9103V19.1686H9.94407C6.32863 19.1686 3.38721 22.11 3.38721 25.7255V31.3726C3.38721 31.7191 3.66818 32 4.01466 32H28.4852C28.8317 32 29.1127 31.7191 29.1127 31.3726V25.7255C29.1127 22.11 26.1713 19.1686 22.5558 19.1686ZM16.25 1.2549C16.9405 1.2549 17.5103 1.81798 17.5044 2.5206C17.1948 2.5014 17.2338 2.51382 15.3088 2.5098C15.2035 2.5098 15.0993 2.51413 14.9956 2.5206C14.9896 1.81898 15.5584 1.2549 16.25 1.2549ZM15.3088 3.76471H17.1911C19.0533 3.76471 20.604 5.1237 20.9036 6.90196H11.5963C11.8959 5.1237 13.4466 3.76471 15.3088 3.76471ZM11.5441 12.5804V8.15686H20.9558V12.5804C20.9558 15.1752 18.8448 17.2863 16.25 17.2863C13.6551 17.2863 11.5441 15.1752 11.5441 12.5804ZM17.6617 18.3718V20.2667C17.6617 21.0451 17.0284 21.6784 16.25 21.6784C15.4715 21.6784 14.8382 21.0451 14.8382 20.2667V18.3718C15.7348 18.5904 16.7061 18.6048 17.6617 18.3718ZM27.8578 30.7451H4.64211V25.7255C4.64211 22.802 7.02052 20.4235 9.94407 20.4235H13.5883C13.6698 21.8212 14.8323 22.9333 16.25 22.9333C17.6676 22.9333 18.8301 21.8212 18.9116 20.4235H22.5558C25.4794 20.4235 27.8578 22.802 27.8578 25.7255V30.7451Z'
                    fill='#0C6FF3'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_42428'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.25)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
