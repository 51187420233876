import { ServiceCategoryInitCreate, ServiceInitCreate } from '@esg/business-account';
import { AppButton, FlexBox, Text } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { ServiceItem } from './ServiceItem';
import { translateCodes } from '@/locales';

interface ServiceCategoryItemProps{
    dataCategory?: ServiceCategoryInitCreate;
    dataService?: ServiceInitCreate[];
    showButton?: boolean;
    onCreateService?: (id?: number) => void;
    onCategory?: (id: number) => void;
    children?: React.ReactNode;
    onEditService?: (categoryIndex?: number, serviceIndex?: number) => void;
    onDeleteService?: (categoryIndex?: number, serviceIndex?: number) => void;
    watch: UseFormWatch<FieldValues>
}

export const ServiceCategoryItem = (props : ServiceCategoryItemProps) => {
    const { dataCategory, dataService, showButton, onCreateService, onCategory, onEditService, onDeleteService, watch } = props;
    const { t } = useTranslation();

    return (
        <FlexBox direction='column'>
            <FlexBox justifyContent='space-between' alignItems='center'>
                <Text
                    fontSize={14}
                    color='#475467'
                    fontWeight={500}
                >
                    {dataCategory?.name}
                    {dataCategory && showButton && <AppButton
                        className='edit-button'
                        icon={<EditOutlined />}
                        type='link'
                        onClick={() => onCategory?.(dataCategory.tempId!)}
                        padding={0}
                    />}
                </Text>
                {showButton && <AppButton
                    translateCode={t(translateCodes.CREATE_SERVICE)}
                    icon={<PlusCircleFilled />}
                    type='link'
                    onClick={()=>onCreateService?.(dataCategory?.tempId)}
                    padding={0}
                />}
            </FlexBox>
            <ServiceItem 
                dataService={dataService?.filter(o=>o.tempCategoryId == dataCategory?.tempId)} 
                showButton={showButton}
                onEdit={onEditService}
                onDelete={onDeleteService}
                watch={watch}
            />
        </FlexBox>
    );
};
