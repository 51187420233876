import React from 'react';
import { IconProps } from '../../Types';

export const IconTattooPiercing = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_35654)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M27.167 8.94948C27.4111 9.19356 27.4111 9.58929 27.167 9.83337L21.1194 15.8809C20.8754 16.125 20.4796 16.125 20.2355 15.8809C19.9915 15.6368 19.9915 15.2411 20.2355 14.997L26.2831 8.94948C26.5272 8.7054 26.9229 8.7054 27.167 8.94948ZM30.1262 11.9087C30.3703 12.1528 30.3703 12.5485 30.1262 12.7926L24.0786 18.8402C23.8345 19.0843 23.4388 19.0843 23.1947 18.8402C22.9507 18.5961 22.9507 18.2004 23.1947 17.9563L29.2423 11.9087C29.4864 11.6646 29.8821 11.6646 30.1262 11.9087Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24.2078 5.99037C24.4518 6.23445 24.4518 6.63018 24.2078 6.87426L18.1602 12.9218C17.9161 13.1658 17.5204 13.1658 17.2763 12.9218C17.0322 12.6777 17.0322 12.2819 17.2763 12.0379L23.3239 5.99037C23.568 5.74629 23.9637 5.74629 24.2078 5.99037Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.5073 10.4747C13.7514 10.2307 14.1471 10.2307 14.3912 10.4747L15.1633 11.2469C15.4074 11.4909 15.4074 11.8867 15.1633 12.1308C14.9192 12.3748 14.5235 12.3748 14.2794 12.1308L13.5073 11.3586C13.2632 11.1146 13.2632 10.7188 13.5073 10.4747Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.36113 25.4756C3.60521 25.7197 3.60521 26.1154 3.36113 26.3595L1.81695 27.9037C1.57287 28.1478 1.17714 28.1478 0.933059 27.9037C0.68898 27.6596 0.68898 27.2639 0.933059 27.0198L2.47725 25.4756C2.72133 25.2315 3.11706 25.2315 3.36113 25.4756Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.871 4.0963C22.1151 3.85222 22.5108 3.85222 22.7549 4.0963L32.2029 13.5442C32.9323 14.2736 32.9322 15.4636 32.2029 16.1929L25.7141 22.6817C24.9848 23.411 23.7949 23.4111 23.0654 22.6817L16.0448 15.6611L14.4094 17.2965C14.1653 17.5406 13.7696 17.5406 13.5255 17.2965C13.2815 17.0524 13.2815 16.6567 13.5255 16.4126L15.6028 14.3353C15.8469 14.0912 16.2427 14.0912 16.4867 14.3353L23.9493 21.7978C23.9493 21.7978 23.9493 21.7978 23.9493 21.7978C24.1906 22.039 24.5891 22.039 24.8302 21.7978L25.2707 21.3573L15.8242 11.9109L12.424 15.3111C12.18 15.5551 11.7842 15.5551 11.5402 15.3111C11.2961 15.067 11.2961 14.6713 11.5402 14.4272L15.3823 10.585C15.6264 10.341 16.0221 10.341 16.2662 10.585L26.1546 20.4734L31.319 15.309C31.5602 15.0678 31.5602 14.6693 31.319 14.4281L21.871 4.98019C21.627 4.73611 21.627 4.34038 21.871 4.0963ZM5.98911 20.8622C6.23319 21.1063 6.23319 21.502 5.98911 21.7461L3.58167 24.1535C3.27975 24.4554 3.27975 24.9531 3.58167 25.255C3.88353 25.5568 4.3813 25.5568 4.68316 25.255L7.0906 22.8475C7.33468 22.6035 7.73041 22.6035 7.97448 22.8475C8.21856 23.0916 8.21856 23.4874 7.97448 23.7314L5.56705 26.1389C4.77703 26.9289 3.4878 26.9289 2.69778 26.1389C1.9077 25.3488 1.9077 24.0597 2.69778 23.2696L5.10522 20.8622C5.3493 20.6181 5.74503 20.6181 5.98911 20.8622Z'
                    fill='#0C6FF3'
                />
                <path
                    d='M11.0811 13.3426L4.02051 20.4032L8.43321 24.8159L15.4938 17.7553L11.0811 13.3426Z'
                    fill='#9BD7FD'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_35654'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.75)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
