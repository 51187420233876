import './BranchSetup.scss';
import {
    SelectField,
    FlexBox,
    InputField,
    Spacer,
    useLocationOptions,
    WorkingTime,
    Text,
} from '@esg/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoLocaleProvider from './AutoLocaleProvider';
import { translateCodes } from '@/locales';

export const BranchSetup = () => {
    const { control, watch, setValue } = useFormContext();
    
    const { t } = useTranslation();
    const currentCountry = watch('country');
    const currentStates = watch('state');
    const { citiesOption, countriesOption, statesOption } = useLocationOptions(currentCountry, currentStates);
    
    return (
        <AutoLocaleProvider 
            countriesOption={countriesOption} 
            statesOption={statesOption}
        >
            <FlexBox justifyContent='space-between' gap={16}>
                <InputField
                    name='branchName'
                    label={t(translateCodes.BRANCH_NAME)}
                    placeholder={t(translateCodes.BRANCH_NAME_PLACEHOLDER)}
                    rules={{ 
                        required: t(translateCodes.VAL_AU_08), 
                        maxLength: 255
                    }}
                />
                <SelectField
                    onSelect={() => {
                        setValue('city', null);
                        setValue('state', null);
                    }}
                    showSearch
                    allowClear
                    options={countriesOption}
                    name='country'
                    label={t(translateCodes.COUNTRY)}
                    placeholder={t(translateCodes.COUNTRY_PLACEHOLDER)}
                    rules={{ required: t(translateCodes.BRANCH_COUNTRY_IS_REQUIRED)}}
                />
            </FlexBox>
            <Spacer bottom={16} />
            <FlexBox justifyContent='space-between' gap={16}>
                <SelectField
                    onSelect={() => {
                        setValue('city', null);
                    }}
                    options={statesOption}
                    name='state'
                    showSearch
                    allowClear
                    label={t(translateCodes.STATE)}
                    placeholder={t(translateCodes.STATE_PLACEHOLDER)}
                    rules={{ required: t(translateCodes.VAL_AU_16)}}
                />
                <SelectField
                    options={citiesOption}
                    name='city'
                    showSearch
                    allowClear
                    label={t(translateCodes.CITY)}
                    placeholder={t(translateCodes.CITY_PLACEHOLDER)}
                    rules={{ required: t(translateCodes.VAL_AU_17)}}
                />
            </FlexBox>
            <Spacer bottom={16} />
            <FlexBox justifyContent='space-between' gap={16}>
                <InputField
                    name='postalCode'
                    label={t(translateCodes.POSTCODE)}
                    placeholder={t(translateCodes.POSTCODE_PLACEHOLDER)}
                    rules={{ 
                        required: t(translateCodes.VAL_AU_18),
                        maxLength: 10
                    }}
                />
                <InputField
                    name='address'
                    label={t(translateCodes.BRANCH_ADDRESS)}
                    placeholder={t(translateCodes.BRANCH_ADDRESS_PLACEHOLDER)}
                    rules={{ 
                        required: t(translateCodes.VAL_AU_09),
                        maxLength: 255
                    }}
                />
            </FlexBox>
            <Spacer type='padding' bottom='lg' />
            <FlexBox direction='column' justifyContent='space-between' gap={16}>
                <Spacer bottom={6} left={0} >
                    <Text
                        fontWeight={600}
                        lineHeight='20px'
                        color='#344054'
                        translateCode={t(translateCodes.BUSINESS_HOUR_NAME)}
                    />
                </Spacer>
                <WorkingTime
                    name='businessHour'
                    control={control}
                    watch={watch}
                    useAddBreakTime={false}
                />
            </FlexBox>
            <Spacer type='padding' bottom='lg' />
        </AutoLocaleProvider>
    );
};
