import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    AppButton,
    Block,
    ExternalLink,
    FlexBox,
    InternalLink,
    Paragraph,
    urlUtil
} from '@esg/ui';
import { Spin, theme } from 'antd';
import React from 'react';

import { authPaths, authTheme } from '..';

import { AuthHeader } from '.';

import { translateCodes } from '@/locales';
import { env } from '@/configs';
const { useToken } = theme;

interface EmailCheckProps {
    handler: () => void;
    description: string;
    isLoading: boolean;
    userEmail?: string;
    userId?: string;
    token?: string;
}

export const EmailCheck = (props: EmailCheckProps) => {
    const redirectParams = {
        client_id: env.MANAGEMENT_CLIENT_ID,
        redirect_uri: env.MANAGEMENT_LOGIN_CALLBACK_PAGE
    };
    const { token } = useToken();

    return (
        <FlexBox direction='column' gap={32} alignItems='center'>
            {props.isLoading ? <Spin fullscreen size='large' /> : null}
            <AuthHeader
                headTranslateCode={translateCodes.CHECK_YOUR_EMAIL}
                descTranslateCode={props.description}
                srcLogo={authTheme.icon.mailIcon}
                userMail={props.userEmail}
            />
            <AppButton
                href='mailto:'
                type='primary'
                width='100%'
                translateCode={translateCodes.OPEN_EMAIL_APP}
            />
            <Block textAlign='center'>
                <InternalLink
                    href={`${authPaths.verifyEmail}?userId=${props.userId}&secret=${props.token}`}
                >
                    Click to verify email (Dev only)
                </InternalLink>
            </Block>
            <FlexBox>
                <Paragraph
                    translateCode={translateCodes.DIDNT_RECEIVE_THE_EMAIL}
                />
                &nbsp;
                <ExternalLink
                    color={token.colorPrimary}
                    translateCode={translateCodes.CLICK_TO_RESEND}
                    onclick={props.handler}
                />
            </FlexBox>
            <AppButton
                href={urlUtil.toRelativeUrl(env.IDENTITY_LOGIN_PAGE, redirectParams)}
                type='text'
                icon={<ArrowLeftOutlined />}
                translateCode={translateCodes.BACK_TO_LOG_IN}
            />
        </FlexBox>
    );
};
