import React from 'react';
import { IconProps } from '../../Types';

export const IconHairSalon = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M31.3529 5.21313C32.3822 6.24251 32.3822 7.91126 31.3529 8.94063L30.5541 9.73938L27.8916 7.07688L29.7554 5.21313C29.976 4.99251 30.2647 4.88251 30.5541 4.88251C30.8435 4.88251 31.1322 4.99251 31.3529 5.21313Z'
                fill='#9BD7FD'
            />
            <path
                d='M27.5365 1.39694C27.9778 1.83819 27.9778 2.55319 27.5365 2.99444L25.6728 4.85819L23.0103 2.19569L23.809 1.39694C24.3234 0.882561 24.9984 0.625061 25.6728 0.625061C26.3471 0.625061 27.0221 0.882561 27.5365 1.39694Z'
                fill='#9BD7FD'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.7807 13.9705C11.1156 14.0542 11.3192 14.3936 11.2355 14.7284L10.4339 17.9351L14.8146 22.3159L18.0212 21.5142C18.3561 21.4305 18.6954 21.6341 18.7792 21.969C18.8629 22.3038 18.6593 22.6432 18.3244 22.7269L14.7745 23.6144C14.5615 23.6676 14.3362 23.6052 14.181 23.45L9.29977 18.5687C9.14453 18.4135 9.08213 18.1882 9.13537 17.9752L10.0228 14.4253C10.1065 14.0904 10.4459 13.8868 10.7807 13.9705Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.69442 17.5036C9.87624 17.4897 10.0551 17.5559 10.184 17.6848L15.0652 22.566C15.1941 22.695 15.2603 22.8738 15.2464 23.0556C15.2325 23.2374 15.1399 23.4041 14.9929 23.512L9.85907 27.2768C9.85906 27.2768 9.85905 27.2768 9.85905 27.2768C8.73446 28.1015 7.17637 27.9823 6.19026 26.9962L5.75388 26.5598C4.76776 25.5737 4.64858 24.0156 5.47332 22.891C5.47332 22.891 5.47332 22.891 5.47332 22.891L9.23807 17.7572C9.34591 17.6101 9.51259 17.5175 9.69442 17.5036ZM9.81527 19.0838L6.48133 23.6302L6.48132 23.6302C6.02144 24.2573 6.08789 25.1261 6.63776 25.676L7.07414 26.1123C7.62402 26.6622 8.4928 26.7286 9.11983 26.2688L9.11985 26.2688L13.6662 22.9348L9.81527 19.0838Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M29.3133 4.77119C29.6561 4.42846 30.1059 4.25751 30.554 4.25751C31.0021 4.25751 31.452 4.42846 31.7947 4.77119C33.0682 6.04463 33.0682 8.1091 31.7947 9.38254L21.2773 19.9C21.0332 20.1441 20.6375 20.1441 20.3934 19.9C20.1493 19.6559 20.1493 19.2602 20.3934 19.0161L30.9108 8.49867C31.6961 7.71337 31.6961 6.44036 30.9108 5.65507C30.8123 5.55654 30.6847 5.5075 30.554 5.5075C30.4234 5.5075 30.2957 5.55654 30.1972 5.65507L18.6148 17.2375C18.3707 17.4816 17.975 17.4816 17.7309 17.2375C17.4868 16.9934 17.4868 16.5977 17.7309 16.3536L29.3133 4.77119Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23.3675 0.954989C24.0038 0.318673 24.8399 6.10352e-05 25.6732 6.10352e-05C26.5065 6.10352e-05 27.3425 0.318673 27.9788 0.954989C28.6642 1.64031 28.6642 2.75103 27.9788 3.43636L16.3964 15.0188C16.1523 15.2629 15.7566 15.2629 15.5125 15.0188C15.2685 14.7747 15.2685 14.379 15.5125 14.1349L27.095 2.55248C27.2921 2.35531 27.2921 2.03604 27.095 1.83887C26.7025 1.44644 26.1886 1.25005 25.6732 1.25005C25.1577 1.25005 24.6438 1.44644 24.2514 1.83887L13.7339 12.3563C13.4899 12.6004 13.0941 12.6004 12.85 12.3563C12.606 12.1122 12.606 11.7165 12.85 11.4724L23.3675 0.954989Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.85622 25.8945C7.10028 26.1385 7.10025 26.5343 6.85615 26.7783L1.81691 31.8169C1.57281 32.061 1.17709 32.0609 0.933027 31.8168C0.688967 31.5727 0.688994 31.177 0.933087 30.933L5.97234 25.8944C6.21643 25.6503 6.61216 25.6504 6.85622 25.8945Z'
                fill='#0C6FF3'
            />
            <path
                d='M19.948 24.0257C19.4826 24.0257 19.0172 23.8486 18.6629 23.4943L9.25554 14.087C8.54698 13.3783 8.54698 12.2254 9.25554 11.5168L10.2318 10.5406C10.9405 9.83199 12.0933 9.83218 12.8019 10.5406L22.2093 19.948C22.9178 20.6566 22.9178 21.8095 22.2093 22.5181L21.2331 23.4943C20.8787 23.8485 20.4134 24.0257 19.948 24.0257Z'
                fill='#9BD7FD'
            />
        </svg>
    );
};
