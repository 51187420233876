import './SetupLayout.scss';
import {
    Block,
    FlexBox,
    Spacer,
    Translate,
} from '@esg/ui';
import { Image, Layout, Steps } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { SetupForm } from './children';

import { translateCodes } from '@/locales';
import { authTheme } from '@/pages/auth/@shared';

const { Sider } = Layout;

const stepsItem = [
    {
        title: <Translate translateCode={translateCodes.SALON_INFO} />,
    },
    {
        title: <Translate translateCode={translateCodes.BRANCH_SETUP} />,
    },
    {
        title: <Translate translateCode={translateCodes.MAIN_BUSINESS_CATEGORY} />,
    },
    {
        title: <Translate translateCode={translateCodes.SERVICE_SETUP} />,
    },
    {
        title: <Translate translateCode={translateCodes.EMPLOYEE_SETUP} />,
    }
];

export const SetupLayout = () => {
    const pathName = window.location.pathname;

    const currentStep = pathName.split('/').pop();

    return (
        <Layout id='setup-layout'>
            <Sider className='sider' width={360}>
                <FlexBox direction='column' alignItems='center'>
                    <Spacer top='xl2' bottom='xl5'>
                        <Image
                            src={authTheme.layout.logo}
                            width={168}
                            preview={false}
                        />
                    </Spacer>
                    <Block width={294}>
                        <Steps
                            className='setup-steps'
                            direction='vertical'
                            current={Number(currentStep)}
                            items={stepsItem}
                        />
                    </Block>
                </FlexBox>
            </Sider>
            <SetupForm
                currentStep={currentStep ?? '0'}
            >
                <Outlet />
            </SetupForm>
        </Layout>
    );
};
