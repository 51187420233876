import React from 'react';
import { IconProps } from '../../Types';

export const IconBeautySalon = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M3.38525 16.3138V27.6097H19.074V16.3138H3.38525ZM11.2296 24.472C10.1899 24.472 9.347 23.6291 9.347 22.5893C9.347 21.5496 11.2296 19.4516 11.2296 19.4516C11.2296 19.4516 13.1123 21.5496 13.1123 22.5893C13.1123 23.6291 12.2694 24.472 11.2296 24.472Z'
                fill='#9BD7FD'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.695 19.0341L11.2298 19.4515L10.7646 19.0341C10.8832 18.902 11.0523 18.8265 11.2298 18.8265C11.4073 18.8265 11.5764 18.902 11.695 19.0341ZM11.2298 20.4243C11.0958 20.5922 10.9455 20.7873 10.7959 20.9954C10.5697 21.3099 10.3538 21.6418 10.197 21.9464C10.0298 22.2712 9.97217 22.4857 9.97217 22.5893C9.97217 23.2839 10.5352 23.8469 11.2298 23.8469C11.9244 23.8469 12.4874 23.2839 12.4874 22.5893C12.4874 22.4857 12.4297 22.2712 12.2625 21.9464C12.1058 21.6418 11.8899 21.3099 11.6637 20.9954C11.5141 20.7873 11.3638 20.5922 11.2298 20.4243ZM13.374 21.3743C13.5598 21.7353 13.7374 22.173 13.7374 22.5893C13.7374 23.9742 12.6147 25.0969 11.2298 25.0969C9.84486 25.0969 8.72217 23.9742 8.72217 22.5893C8.72217 22.173 8.89982 21.7353 9.08562 21.3743C9.28182 20.9932 9.5366 20.6055 9.78107 20.2656C10.0272 19.9234 10.2719 19.617 10.4544 19.3967C10.546 19.2863 10.6225 19.1968 10.6766 19.1344C10.7036 19.1032 10.7251 19.0787 10.7401 19.0617C10.7476 19.0533 10.7534 19.0466 10.7576 19.042L10.7624 19.0366L10.7645 19.0343C10.7646 19.0342 10.7646 19.0341 11.2298 19.4515C11.695 19.0341 11.695 19.0342 11.6951 19.0342L11.6971 19.0365L11.702 19.042C11.7061 19.0466 11.712 19.0532 11.7195 19.0617C11.7345 19.0787 11.7559 19.1032 11.783 19.1344C11.8371 19.1968 11.9136 19.2863 12.0051 19.3967C12.1877 19.6169 12.4324 19.9234 12.6785 20.2656C12.923 20.6055 13.1778 20.9931 13.374 21.3743Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.52557 7.52808C6.52557 7.1829 6.80539 6.90308 7.15057 6.90308H15.3088C15.6539 6.90308 15.9338 7.1829 15.9338 7.52808V9.06501C15.9338 9.85612 16.3291 10.595 16.9874 11.0338L16.9874 11.0338L18.0887 11.768C18.0887 11.768 18.0887 11.768 18.0887 11.7681C19.0948 12.4387 19.699 13.5678 19.699 14.7769V28.7603C19.699 30.5496 18.2486 32 16.4593 32H5.99994C4.2107 32 2.76025 30.5496 2.76025 28.7603V14.7769C2.76025 13.5678 3.36458 12.4387 4.37057 11.768L4.37057 11.768L5.47187 11.0339C5.47188 11.0339 5.47188 11.0339 5.47189 11.0339C6.1302 10.595 6.52557 9.85616 6.52557 9.06501V7.52808ZM7.77557 8.15308V9.06501C7.77557 10.2741 7.17132 11.4032 6.16526 12.0739L6.16525 12.0739L5.06394 12.8081C5.06394 12.8081 5.06394 12.8081 5.06393 12.8081C4.40567 13.247 4.01025 13.9858 4.01025 14.7769V28.7603C4.01025 29.8592 4.90106 30.75 5.99994 30.75H16.4593C17.5582 30.75 18.449 29.8592 18.449 28.7603V14.7769C18.449 13.9857 18.0536 13.2469 17.3953 12.8081L17.3953 12.8081L16.294 12.0739C16.294 12.0739 16.294 12.0739 16.294 12.0738C15.288 11.4032 14.6838 10.274 14.6838 9.06501V8.15308H7.77557Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.64282 5.67157C4.64282 4.61877 5.49627 3.76532 6.54907 3.76532H15.91C16.9628 3.76532 17.8162 4.61877 17.8163 5.67151V6.24682C17.8163 7.29962 16.9629 8.15307 15.9101 8.15307H6.54907C5.49627 8.15307 4.64282 7.29962 4.64282 6.24682V5.67157ZM6.54907 5.01532C6.18663 5.01532 5.89282 5.30912 5.89282 5.67157V6.24682C5.89282 6.60927 6.18663 6.90307 6.54907 6.90307H15.9101C16.2725 6.90307 16.5663 6.60927 16.5663 6.24682V5.67163C16.5663 5.67161 16.5663 5.67164 16.5663 5.67163C16.5663 5.30915 16.2724 5.01532 15.91 5.01532H6.54907Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.78052 2.54169C7.78052 1.13795 8.91847 0 10.3222 0H12.1368C13.5406 0 14.6785 1.13793 14.6785 2.54169C14.6785 2.54168 14.6785 2.5417 14.6785 2.54169V4.39031C14.6785 4.73549 14.3986 5.01531 14.0535 5.01531H8.40552C8.06034 5.01531 7.78052 4.73549 7.78052 4.39031V2.54169ZM10.3222 1.25C9.60882 1.25 9.03052 1.8283 9.03052 2.54169V3.76531H13.4285V2.54169C13.4285 1.82834 12.8502 1.25 12.1368 1.25H10.3222Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24.7639 7.69183C24.3925 7.69183 24.0918 7.99247 24.0918 8.36396V12.0872C24.0918 12.4324 23.812 12.7122 23.4668 12.7122C23.1216 12.7122 22.8418 12.4324 22.8418 12.0872V8.36396C22.8418 7.30207 23.7021 6.44183 24.7639 6.44183H25.9349C26.9968 6.44183 27.857 7.30218 27.857 8.36396V12.0872C27.857 12.4324 27.5772 12.7122 27.232 12.7122C26.8869 12.7122 26.607 12.4324 26.607 12.0872V8.36396C26.607 7.99249 26.3064 7.69183 25.9349 7.69183H24.7639Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23.4668 14.6C23.812 14.6 24.0918 14.8798 24.0918 15.225V28.1085C24.0918 28.5442 24.1624 28.9733 24.2982 29.3815C24.4339 29.789 24.6353 30.1746 24.897 30.5239C25.1233 30.8254 25.5758 30.8253 25.802 30.5237C26.3247 29.8267 26.6071 28.9793 26.6071 28.1086V15.225C26.6071 14.8798 26.8869 14.6 27.2321 14.6C27.5773 14.6 27.8571 14.8798 27.8571 15.225V28.1086C27.8571 29.2498 27.4869 30.3604 26.8021 31.2736L26.802 31.2737C26.0757 32.2421 24.6231 32.2421 23.8968 31.2737L23.8966 31.2734C23.5546 30.8169 23.2904 30.3115 23.1122 29.7763C22.9343 29.2419 22.8418 28.6795 22.8418 28.1085V15.225C22.8418 14.8798 23.1216 14.6 23.4668 14.6Z'
                fill='#0C6FF3'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.73 12.7123C22.4424 12.7123 22.2092 12.9455 22.2092 13.2332V14.0792C22.2092 14.3669 22.4424 14.6 22.73 14.6H27.969C28.2567 14.6 28.4898 14.3668 28.4898 14.0793V13.2332C28.4898 12.9455 28.2566 12.7123 27.969 12.7123H22.73ZM20.9592 13.2332C20.9592 12.2552 21.7521 11.4623 22.73 11.4623H27.969C28.947 11.4623 29.7398 12.2552 29.7398 13.2332V14.0792C29.7398 14.0791 29.7398 14.0792 29.7398 14.0792C29.7399 15.0571 28.947 15.85 27.969 15.85H22.73C21.7521 15.85 20.9592 15.0572 20.9592 14.0792V13.2332Z'
                fill='#0C6FF3'
            />
        </svg>
    );
};
