import React from 'react';
import { IconProps } from '../../Types';

export const IconEyebrowsLashes = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <mask
                id='mask0_4955_36903'
                style={{ maskType: 'luminance' }}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='33'
                height='32'
            >
                <path
                    d='M0.25 1.90735e-06H32.25V32H0.25V1.90735e-06Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_4955_36903)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M17.9636 9.15967C17.9636 9.50485 18.2434 9.78467 18.5886 9.78467H21.9809C23.0969 9.78467 24.0015 8.87995 24.0015 7.76404V2.02061C24.0015 0.904615 23.0969 -1.81198e-05 21.9809 -1.81198e-05H18.5886C18.2434 -1.81198e-05 17.9636 0.279804 17.9636 0.624981V9.15967ZM19.2136 8.53467V1.24998H21.9809C22.4065 1.24998 22.7515 1.59497 22.7515 2.02061V7.76404C22.7515 8.18963 22.4065 8.53467 21.9809 8.53467H19.2136Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.209 0.625C16.209 0.970178 16.4888 1.25 16.834 1.25H18.5887C18.9338 1.25 19.2137 0.970178 19.2137 0.625C19.2137 0.279822 18.9338 1.19209e-07 18.5887 1.19209e-07H16.834C16.4888 1.19209e-07 16.209 0.279822 16.209 0.625Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.209 3.46991C16.209 3.81509 16.4888 4.09491 16.834 4.09491H18.5887C18.9338 4.09491 19.2137 3.81509 19.2137 3.46991C19.2137 3.12473 18.9338 2.84491 18.5887 2.84491H16.834C16.4888 2.84491 16.209 3.12473 16.209 3.46991Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.209 6.31476C16.209 6.65994 16.4888 6.93976 16.834 6.93976H18.5887C18.9338 6.93976 19.2137 6.65994 19.2137 6.31476C19.2137 5.96958 18.9338 5.68976 18.5887 5.68976H16.834C16.4888 5.68976 16.209 5.96958 16.209 6.31476Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.209 9.15967C16.209 9.50485 16.4888 9.78467 16.834 9.78467H18.5887C18.9338 9.78467 19.2137 9.50485 19.2137 9.15967C19.2137 8.81449 18.9338 8.53467 18.5887 8.53467H16.834C16.4888 8.53467 16.209 8.81449 16.209 9.15967Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M20.9824 13.3564C21.3276 13.3564 21.6074 13.0766 21.6074 12.7314V9.38923C21.6074 9.04405 21.3276 8.76423 20.9824 8.76423C20.6372 8.76423 20.3574 9.04405 20.3574 9.38923V12.7314C20.3574 13.0766 20.6372 13.3564 20.9824 13.3564Z'
                    fill='#0C6FF3'
                />
                <path d='M23.015 32H18.95V12.2886H23.015V32Z' fill='#9BD7FD' />
                <path
                    d='M13.6672 17.7168H9.12354V10.3775H13.6672V17.7168Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.3953 2.13745C11.7404 2.13745 12.0203 1.85763 12.0203 1.51245V0.625014C12.0203 0.279836 11.7404 1.38283e-05 11.3953 1.38283e-05C11.0501 1.38283e-05 10.7703 0.279836 10.7703 0.625014V1.51245C10.7703 1.85763 11.0501 2.13745 11.3953 2.13745Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.49854 31.375C8.49854 31.7202 8.77836 32 9.12354 32H13.6672C14.0124 32 14.2922 31.7202 14.2922 31.375V5.33207C14.2922 4.98689 14.0124 4.70707 13.6672 4.70707H9.12354C8.77836 4.70707 8.49854 4.98689 8.49854 5.33207V31.375ZM9.74853 30.75V5.95706H13.0422V30.75H9.74853Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.58025 5.64101C8.69136 5.83637 8.89879 5.95703 9.12354 5.95703H13.6672C13.892 5.95703 14.0994 5.83637 14.2105 5.64101C14.3216 5.44566 14.3193 5.20569 14.2044 5.01253L11.9326 1.19297C11.8199 1.00355 11.6158 0.88747 11.3954 0.887469C11.175 0.887468 10.9709 1.00355 10.8582 1.19297L8.58637 5.01253C8.47148 5.20568 8.46915 5.44565 8.58025 5.64101ZM10.2225 4.70703L11.3954 2.73508L12.5683 4.70703H10.2225Z'
                    fill='#0C6FF3'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.56128 22.8934C8.56128 23.2385 8.8411 23.5184 9.18628 23.5184H13.6046C13.9498 23.5184 14.2296 23.2385 14.2296 22.8934C14.2296 22.5482 13.9498 22.2684 13.6046 22.2684H9.18628C8.8411 22.2684 8.56128 22.5482 8.56128 22.8934Z'
                    fill='#0C6FF3'
                />
            </g>
        </svg>
    );
};
