import React from 'react';
import { IconProps } from '../../Types';

export const IconOtherBusiness = (props: IconProps) => {
    return (
        <svg
            width='33'
            height='32'
            viewBox='0 0 33 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_4955_36450)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7501 13.5V9.96454L11.8307 8.04511C10.8584 7.07286 9.26742 7.07286 8.29511 8.04511C7.32286 9.01736 7.32286 10.6084 8.29511 11.5807L10.2145 13.5001L13.7501 13.5Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.7501 18.5H10.2145L8.29511 20.4194C7.32286 21.3917 7.32286 22.9827 8.29511 23.955C9.26742 24.9272 10.8584 24.9272 11.8306 23.955L13.75 22.0356V18.5H13.7501Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.75 18.5V22.0356L20.6694 23.955C21.6417 24.9272 23.2326 24.9272 24.2049 23.955C25.1772 22.9827 25.1772 21.3917 24.2049 20.4194L22.2855 18.5H18.75Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.75 13.5H22.2855L24.2049 11.5806C25.1772 10.6083 25.1772 9.01736 24.2049 8.04504C23.2326 7.07279 21.6417 7.07279 20.6694 8.04504L18.75 9.96442V13.5Z'
                    fill='#9BD7FD'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.25 1.25C15.2202 1.25 14.375 2.09517 14.375 3.12499V13.5C14.375 13.8452 14.0952 14.125 13.75 14.125H3.37499C2.34517 14.125 1.5 14.9702 1.5 16C1.5 17.0298 2.34517 17.875 3.37499 17.875H13.75C14.0952 17.875 14.375 18.1548 14.375 18.5V28.875C14.375 29.9048 15.2202 30.75 16.25 30.75C17.2798 30.75 18.125 29.9048 18.125 28.875V18.5C18.125 18.1548 18.4048 17.875 18.75 17.875H29.125C30.1548 17.875 31 17.0298 31 16C31 14.9702 30.1548 14.125 29.125 14.125H18.75C18.4048 14.125 18.125 13.8452 18.125 13.5V3.12499C18.125 2.09517 17.2798 1.25 16.25 1.25ZM13.125 3.12499C13.125 1.40482 14.5298 0 16.25 0C17.9702 0 19.375 1.40482 19.375 3.12499V12.875H29.125C30.8452 12.875 32.25 14.2798 32.25 16C32.25 17.7202 30.8452 19.125 29.125 19.125H19.375V28.875C19.375 30.5952 17.9702 32 16.25 32C14.5298 32 13.125 30.5952 13.125 28.875V19.125H3.37499C1.65482 19.125 0.25 17.7202 0.25 16C0.25 14.2798 1.65482 12.875 3.37499 12.875H13.125V3.12499Z'
                    fill='#0C6FF3'
                />
            </g>
            <defs>
                <clipPath id='clip0_4955_36450'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                        transform='translate(0.25)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
