
import { Paragraph, Spacer, Text } from '@esg/ui';
import React from 'react';
import { ServiceInitCreate } from '@esg/business-account';
import { translateCodes } from '@/locales';

export const ServiceDeleteConfirm = ({ item }: { item: ServiceInitCreate}) => {
    
    return (
        <Spacer top='xs'>
            <Paragraph fontSize={18} fontWeight={700}>
                <Text translateCode={translateCodes.DELETE_SERVICE_TITLE} />
            </Paragraph>
            <Spacer type='margin' top={8} />
            <Paragraph>
                <Text translateCode={translateCodes.DELETE_SERVICE_CONFIRM_MESSAGE} />:
                <Text fontWeight={700}> 
                    {' '+ item?.name}
                </Text>
            </Paragraph>
        </Spacer>
    );
};
