import { Block, FlexBox, IconAesthetics, IconBarberShop, IconBeautySalon, IconCheck, IconEyebrowsLashes, IconGymFitness, IconHairSalon, IconMassage, IconNailsSalon, IconOtherBusiness, IconPersonalTrainer, IconSpa, IconTanningStudio, IconTattooPiercing, IconTherapyCentre, IconWaxingSalon, IconWeightLoss } from '@esg/ui';
import { Card } from 'antd';
import React, {useCallback} from 'react';
import './MainBusinessCategory.scss';
import { useFormContext } from 'react-hook-form';
import { BusinessCategoryEnum } from '@esg/shared';

const businessCategoriesOptions = [
    {
        name: 'Hair Salon',
        icon: <IconHairSalon />,
        value: BusinessCategoryEnum.HairSalon,
    },
    {
        name: 'Nails Salon',
        icon: <IconNailsSalon />,
        value: BusinessCategoryEnum.NailsSalon,
    },
    {
        name: 'Barber Shop',
        icon: <IconBarberShop />,
        value: BusinessCategoryEnum.BarberShop,
    },
    {
        name: 'Beauty Salon',
        icon: <IconBeautySalon />,
        value: BusinessCategoryEnum.BeautySalon,
    },
    {
        name: 'Aesthetics',
        icon: <IconAesthetics />,
        value: BusinessCategoryEnum.Aesthetics,
    },
    {
        name: 'Spa',
        icon: <IconSpa />,
        value: BusinessCategoryEnum.Spa,
    },
    {
        name: 'Massage',
        icon: <IconMassage />,
        value: BusinessCategoryEnum.Massage,
    },
    {
        name: 'Waxing Salon',
        icon: <IconWaxingSalon />,
        value: BusinessCategoryEnum.WaxingSalon,
    },
    {
        name: 'Tanning Studio',
        icon: <IconTanningStudio />,
        value: BusinessCategoryEnum.TanningStudio,
    },
    {
        name: 'Eyebrows & Lashes',
        icon: <IconEyebrowsLashes />,
        value: BusinessCategoryEnum.EyebrowsLashes,
    },
    {
        name: 'Tattoo & Piercing',
        icon: <IconTattooPiercing />,
        value: BusinessCategoryEnum.TattooPiercing,
    },
    {
        name: 'Therapy Center',
        icon: <IconTherapyCentre />,
        value: BusinessCategoryEnum.TherapyCenter,
    },
    {
        name: 'Weight Loss',
        icon: <IconWeightLoss />,
        value: BusinessCategoryEnum.WeightLoss,
    },
    {
        name: 'Personal Trainer',
        icon: <IconPersonalTrainer />,
        value: BusinessCategoryEnum.PersonalTrainer,
    },
    {
        name: 'Gym & Fitness',
        icon: <IconGymFitness />,
        value: BusinessCategoryEnum.GymFitness,
    },
    {
        name: 'Other Business',
        icon: <IconOtherBusiness />,
        value: BusinessCategoryEnum.OtherBusiness,
    }
];

export function MainBusinessCategory() {
    const { setValue, watch } = useFormContext();
    const businessValue: number[] = watch('businessCategories');
    const isDisabled = businessValue?.length >= 3;

    const handleChooseCategory = useCallback((category: number) => {
        const isChecked = businessValue?.includes(category);
        if(isDisabled && !isChecked) return;

        const business= businessValue ?? [];
        
        if(businessValue?.includes(category)) {
            setValue('businessCategories', business?.filter((item) => item !== category));
        } else {
            setValue('businessCategories', [...business, category]);
        }
    }, [businessValue, isDisabled, setValue]);

    return (
        <Block className='business-category' >
            {businessCategoriesOptions.map((category) => (
                <Card key={category.value}
                    className={`business-category__card ${businessValue?.includes(category.value) ? 'active' : ''}`}
                    hoverable
                    
                    onClick={() => handleChooseCategory(category.value)}
                >
                    <FlexBox 
                        direction='column' 
                        justifyContent='center' 
                        alignItems='center'
                    >
                        <Block 
                            position='absolute' 
                            top={0} 
                            right={0} 
                            zIndex={2}
                        >
                            <IconCheck color='white' />
                        </Block>
                        <FlexBox>{category.icon}</FlexBox>
                        <Block>{category.name}</Block>
                    </FlexBox>
                </Card>
            ))}
        </Block>
    );
}
