import { AppButton, FlexBox, Translate } from '@esg/ui';
import { Image } from 'antd';
import React from 'react';

import { authTheme } from '..';

import { translateCodes } from '@/locales';

interface GroupSocialButtonProps {
    readonly onGoogleClick: () => void;
}

export const GroupSocialButton = (props: GroupSocialButtonProps) => {
    return (
        <FlexBox direction='column' gap={12}>
            <AppButton onClick={props.onGoogleClick}>
                <FlexBox preset='row-center' gap={8}>
                    <Image src={authTheme.social.googleIcon} preview={false} />
                    <Translate
                        translateCode={translateCodes.CONTINUE_WITH_GOOGLE}
                    />
                </FlexBox>
            </AppButton>
            {
                false && (
                    <AppButton>
                        <FlexBox preset='row-center' gap={8}>
                            <Image
                                src={authTheme.social.facebookIcon}
                                preview={false}
                            />
                            <Translate
                                translateCode={translateCodes.CONTINUE_WITH_FACEBOOK}
                            />
                        </FlexBox>
                    </AppButton>
                )
            }
        </FlexBox>
    );
};
