import { translateCodes } from '@/locales';

export const authRules = {
    lastName: {
        required: translateCodes.LAST_NAME_REQUIRED,
        maxLength: {
            value: 100,
            message: translateCodes.LAST_NAME_MAX_LENGTH,
        },
        minLength: {
            value: 2,
            message: translateCodes.LAST_NAME_MIN_LENGTH,
        },
    },
    firstName: {
        required: translateCodes.FIRST_NAME_REQUIRED,
        maxLength: {
            value: 100,
            message: translateCodes.FIRST_NAME_MAX_LENGTH,
        },
        minLength: {
            value: 2,
            message: translateCodes.FIRST_NAME_MIN_LENGTH,
        },
    },
    password: {
        required: translateCodes.VAL_AU_03,
        pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            message: translateCodes.VAL_AU_12,
        },
        minLength: {
            value: 6,
            message: translateCodes.PASSWORD_MIN_LENGTH,
        },
        maxLength: {
            value: 20,
            message: translateCodes.PASSWORD_MAX_LENGTH,
        },
    },
    passwordLogin: {
        required: translateCodes.VAL_AU_03,
        pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            message: translateCodes.VAL_AU_12,
        },
        minLength: {
            value: 6,
            message: translateCodes.PASSWORD_MIN_LENGTH,
        },
        maxLength: {
            value: 20,
            message: translateCodes.PASSWORD_MAX_LENGTH,
        },
    },
    email: {
        required: translateCodes.VAL_AU_02,
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            message: translateCodes.VAL_AU_10,
        },
        maxLength: {
            value: 255,
            message: translateCodes.MAX_LENGTH_EMAIL,
        },
    },
};
