import {
    Block,
    FlexBox,
    Header,
    Paragraph,
    Spacer
    , useScreenSize } from '@esg/ui';
import { Image } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { translateCodes } from '@/locales';
import { authTheme } from '@/pages/auth/@shared';

export const AuthLayout = () => {
    const layout = useScreenSize();

    if (layout.size == 'md') {
        return (
            <Block height='100vh' >
                <FlexBox preset='column-center'>
                    <Spacer top='xl4' bottom='xl3'>
                        <Image
                            src={authTheme.layout.logo}
                            width={authTheme.layout.logoWith}
                            preview={false}
                        />
                    </Spacer>
                    <Block width={authTheme.layout.contentWith}>
                        <Outlet />
                    </Block>
                    <Spacer bottom='lg' />
                </FlexBox>
            </Block>
        );
    }

    return (
        <FlexBox height='100vh'>
            <FlexBox>
                <Block
                    backgroundImage={`url(${authTheme.layout.backgroundPattern}), linear-gradient(0, #0c6ff3, #0c6ff3)`}
                    backgroundRepeat='no-repeat'
                    backgroundPosition='center'
                    width='100%'
                >
                    <FlexBox direction='column' alignItems='center'>
                        <Spacer bottom='md' />
                        <Image
                            src={authTheme.layout.backgroundImage}
                            preview={false}
                            width='80%'
                        />
                        <Spacer bottom='xl' />
                        <Block textAlign='center'>
                            <Header
                                level={2}
                                color='white'
                                translateCode={translateCodes.PLATFORM_FOR_SALON_SUCCESS}
                            />
                            <Spacer bottom='sm' />
                            <Paragraph
                                color='white'
                                fontSize={18}
                                translateCode={translateCodes.INTELLIGENT_EFFICIENT_EFFORTLESS}
                            />
                        </Block>
                    </FlexBox>
                </Block>
            </FlexBox>
            <FlexBox>
                <Block height='100vh' width='100%'>
                    <FlexBox preset='column-center'>
                        <Spacer top='xl4' bottom='xl3'>
                            <Image
                                src={authTheme.layout.logo}
                                width={authTheme.layout.logoWith}
                                preview={false}
                            />
                        </Spacer>
                        <Block width={authTheme.layout.contentWith}>
                            <Outlet />
                        </Block>
                        <Spacer bottom='lg' />
                    </FlexBox>
                </Block>
            </FlexBox>
        </FlexBox>
    );
};
