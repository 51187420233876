import { FlexBox, FormBase, FormLayoutModal, InputField, SelectField, TextAreaField, useLocalesFormat } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { ServiceCategoryInitCreate, ServiceInitCreate } from '@esg/business-account';
import { useState } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { translateCodes } from '@/locales';
import { serviceRules } from '@/pages/rules/serviceRules';

interface ServiceFormProps {
    readonly categoryId?: number;
    readonly categories?: ServiceCategoryInitCreate[];
    readonly defaultData?: ServiceInitCreate;
    readonly onCancel?: () => void;
    readonly isUpdate?: boolean;
    readonly handleCreateCategory: (value: ServiceCategoryInitCreate) => void;
    readonly onSubmitService: (value: ServiceInitCreate) => void;
    watch: UseFormWatch<FieldValues>
}
export default function ServiceSetupForm(props: ServiceFormProps) {
    const { categoryId, defaultData, isUpdate, onCancel, handleCreateCategory, onSubmitService, categories, watch} = props;
    const { formatCurrencySymbol } = useLocalesFormat();
    const [ categoryList , setCategoryList ] = useState<ServiceCategoryInitCreate[]>(categories ?? []); 
    const {t} = useTranslation();

    return (
        <FormBase 
            onSubmit={onSubmitService} 
            defaultValues={{...defaultData, tempCategoryId: categoryId}}
        >
            {(form) => (
                <FormLayoutModal
                    useSubmitButton={true}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                    cancelTextCode={translateCodes.CANCEL}
                    onCancel={onCancel}
                    alwaysEnableSubmit={!isUpdate}
                >
                    <FlexBox direction='column' gap={16}>
                        <FlexBox >
                            <InputField
                                name='name'
                                label={t(translateCodes.NAME)}
                                placeholder='Enter category name.'
                                rules={serviceRules.name(t)}
                            />
                        </FlexBox>
                        <FlexBox direction='row' gap={16}>
                            <SelectField
                                name='tempCategoryId'
                                allowClear
                                options={categoryList?.map((o)=>({
                                    ...o,
                                    label: o.name,
                                    value: o.tempId,
                                }))}
                                label={t(translateCodes.CATEGORY)}
                                placeholder={t(translateCodes.SERVICE_CATEGORY_PLACEHOLDER)}
                                onCreate={(value) => {
                                    const lastCategory = categoryList?.findLast(o=>o);
                                    const newId = lastCategory ? lastCategory.tempId + 1 : 0;
                                    const newCategory: ServiceCategoryInitCreate = {
                                        tempId: newId,
                                        name: value ?? '',
                                        type: 'added'
                                    };
                                    handleCreateCategory(newCategory);
                                    setCategoryList([...categoryList, newCategory]);
                                    form.setValue('tempCategoryId', newId);
                                }}
                                createTextCode={translateCodes.CREATE_CATEGORY}
                                typeCreate='input'
                                rules={serviceRules.serviceCategory}
                            />
                            <InputField
                                addonBefore='mins'
                                name='duration'
                                label={translateCodes.DURATION_MINUTE}
                                placeholder={translateCodes.ENTER_DURATION}
                                rules={serviceRules.duration}
                            />
                        </FlexBox>
                        <FlexBox gap={16} justifyContent='space-between'>
                            <InputField
                                addonBefore={formatCurrencySymbol(watch('country'), watch('currency'))}
                                name='price'
                                label={translateCodes.PRICE}
                                placeholder={translateCodes.ENTER_PRICE}
                                rules={serviceRules.price}
                            />
                        </FlexBox>
                        <TextAreaField
                            name='description'
                            label={translateCodes.DESCRIPTION}
                            placeholder={translateCodes.ENTER_DESCRIPTION}
                            rules={serviceRules.descriptions(t)}
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
