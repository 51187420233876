import { NotFound } from '@esg/ui';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Auth } from './auth';
import { Setup } from './setup';
import { Users } from './users';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/auth/*' Component={Auth} />
                <Route path='/users/*' Component={Users} />
                <Route path='/setup/*' Component={Setup} />
                <Route path='*' Component={NotFound} />
            </Routes>
        </BrowserRouter>
    );
};