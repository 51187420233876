import { FlexBox, FormBase, FormLayoutModal, InputField } from '@esg/ui';
import { useTranslation } from 'react-i18next';
import { ServiceCategoryInitCreate } from '@esg/business-account';
import { translateCodes } from '@/locales';

interface CategoryFormProps {
    readonly defaultData?: ServiceCategoryInitCreate;
    readonly onCancel?: () => void;
    readonly onSubmit?: (value: ServiceCategoryInitCreate) => void;
}
export default function CategoryForm(props: CategoryFormProps) {
    const { defaultData, onCancel, onSubmit} = props;
    const {t} = useTranslation();
    const isUpdate = defaultData !== undefined;
    
    return (
        <FormBase onSubmit={onSubmit} defaultValues={{...defaultData}}>
            {() => (
                <FormLayoutModal
                    useSubmitButton={true}
                    okTextCode={isUpdate ? translateCodes.SAVE : translateCodes.CREATE}
                    cancelTextCode={translateCodes.CANCEL}
                    onCancel={onCancel}
                    alwaysEnableSubmit={!isUpdate}
                >
                    <FlexBox direction='column' gap={16}>
                        <InputField
                            name='name'
                            label={t(translateCodes.NAME)}
                            placeholder='Enter category name.'
                            rules={{
                                required: t(translateCodes.CATEGORY_NAME_REQUIRED)
                            }}
                        />
                    </FlexBox>
                </FormLayoutModal>
            )}
        </FormBase>
    );
}
