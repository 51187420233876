import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainBusinessCategory } from './main-business-category';
import { BranchSetup } from './branch-setup';
import { Info } from './info';
import { ServiceSetup } from './service-setup';
import { EmployeeSetup } from './Employee-setup';
import { SetupLayout } from '@/layouts/setup';

export const Setup = () => {
    return (
        <Routes>
            <Route Component={SetupLayout}>
                <Route path='/0' Component={Info} />
                <Route path='/1' Component={BranchSetup} />
                <Route path='/2' Component={MainBusinessCategory} />
                <Route path='/3' Component={ServiceSetup} />
                <Route path='/4' Component={EmployeeSetup} />
            </Route>
        </Routes>
    );
};
