/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const serviceRules = {
    name: (t: any) => ({
        required: translateCodes.CATEGORY_NAME_REQUIRED,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_NAME, { number: 50 }),
        },
    }),
    serviceCategory: {
        required: translateCodes.SERVICE_CATEGORY_REQUIRED,
    },
    duration: {
        required: translateCodes.SERVICE_DURATION_REQUIRED,
        pattern : {
            value: /^[0-9]+$/,
            message: translateCodes.SERVICE_DURATION_INVALID,
        }
    },
    price: {
        required: translateCodes.PRICE_REQUIRED,
        pattern : {
            value: /^[0-9]+$/,
            message: translateCodes.PRICE_SERVICE_INVALID,
        }
    },
    descriptions: (t: any) => ({
        maxLength: {
            value: 255,
            message: t(translateCodes.MAX_LENGTH_DESCRIPTION, { number: 255 }),
        },
    }),

};
