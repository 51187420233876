import { accountApi } from '@esg/business-account';
import { viewRequests } from '@esg/framework';
import { useHttpCommand } from '@esg/ui';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { EmailCheck } from '../@shared/components';

import { translateCodes } from '@/locales';

export const EmailCheckResetPassword = () => {
    const { t } = useTranslation();
    const { state: email } = useLocation();

    const { mutateAsync, isPending } = useHttpCommand(
        accountApi.forgotPassword
    );

    const handleResentEmail = useCallback(async () => {
        await mutateAsync(
            {
                body: {
                    email: email,
                },
            },
            {
                onSuccess() {
                    viewRequests.showNotification.send({
                        type: 'success',
                        title: t(translateCodes.RESEND_EMAIL_RESET_PASSWORD),
                        message: t(translateCodes.CHECK_YOUR_EMAIL_NOW),
                    });
                },
            }
        );
    }, [email, mutateAsync, t]);

    return (
        <EmailCheck
            handler={handleResentEmail}
            description={translateCodes.SENT_VERIFY_RESET_PASS}
            userEmail={email}
            isLoading={isPending}
        />
    );
};
